import { Spinner } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl'

function Loading(props) {
    return (
        <Spinner animation="border" role="status">
            <span className="sr-only"><FormattedMessage id="loading" /></span>
        </Spinner>
    );
}

export default Loading;