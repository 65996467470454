import { Component } from 'react';
import { Flow } from 'flow-react';
import { observer } from "mobx-react"
// import { FormattedMessage } from 'react-intl'

import ArticleCarousel from 'Component/ArcticleCarousel';
import ArticleVCard from 'Component/ArticleVCard'
import Loading from 'Component/Loading';
import { Row, Col, Form, Button } from 'react-bootstrap';

import './Home.scss';
import { IoSearch } from 'react-icons/io5';
import ArticleSearchInput from 'Component/ArticleSearchInput';
import Pagi from 'Component/Pagi';

const Home = observer(
  class Home extends Component {
    state={
        articles: [],
        carouselarticles: null,
        articlethumbclips: {},
        carouselarticlethumbclips: null,
        offset: 0,
        limit: 6,
        count: null,
        currentuser: false,
    }

    constructor(props) {
      super(props);
      if (props.limit)
        this.state.limit = props.limit;
    }

    componentDidMount() {
      // Loggedin?
      this.props.flow.getCurrentUser().then(r=>{
          this.setState({currentuser: r},this.getData);
      }).catch(e=>{
          this.getData();
      });
    }

    componentWillReceiveProps(props) {
        if (typeof props.offset !== 'undefined')
            this.setOffset(0);
    }

    setOffset = (offset)=>{
        this.setState({offset: offset, articles: [], articlethumbclips: {}},this.getData);
    }

    getData() {
      let fields = [
          {field:'',type:'BEGIN_OR',value:''},
      ];

      if (this.state.currentuser) 
          fields.push({"field":"allowedusers","type":"REGEXP","value":`(^|;)${this.state.currentuser.getId()}(;|$)`});
     
      fields = [
          ...fields,
          {"field":"publishstatus","type":"=","value":"members"},
          {"field":"publishstatus","type":"=","value":"public"},
          {"field":"publishstatus","type":"=","value":"publicguests"},
          {field:'',type:'END_OR',value:''},
          {"field":"publishdate","type":"ORDER","value":"DESC"}
        ];

      this.props.flow.getDistprojectsForDist(
          'pmdb_newstv',0,fields,
          {limit: this.state.limit, offset: this.state.offset})
          .then(
            res=>{
              if (res.distproject) {
                let distprojects = res.distproject.asArray();
                this.setState({articles: distprojects, count: res.count, offset: res.offset, limit: res.limit});
                if (!this.state.carouselarticles) {
                    this.setState({carouselarticles: distprojects});
                }

                // get thumbnail clips within projects
                let fields = [{field:'txt5',type:'=',value:'ja'},
                              {field:'',type:'BEGIN_OR',value:''},
                             ];
                distprojects.forEach(p=>{
                    fields.push({field:'projectlinkid',type:'=',value:p.getId()});
                });

                // get clips, defined as holding article thumbs
                this.props.flow.getClipsByFields([...fields, {field:'',type:'END_OR',value:''}])
                    .then(cbf=>{
                        if (cbf.clip) {
                            let newarticlethumbs = {};
                            cbf.clip.asArray().forEach(clip=>{
                                let pids = clip.getProjectids();
                                if (pids && pids.length > 0) {
                                    pids.forEach(pid=>{ 
                                        if (!newarticlethumbs[pid]) {
                                            newarticlethumbs[pid] = clip;
                                        }
                                    });
                                }
                            });
                            this.setState({articlethumbclips: newarticlethumbs})
                            if (!this.state.carouselarticlethumbclips) {
                                this.setState({carouselarticlethumbclips: newarticlethumbs});
                            }
                        }
                    });
              }
            });
    }

    render() {
      if (this.state.articles.length < 1)
        return <Loading />;

      let vcards = this.state.articles.map((article,i) => {
        let aid = article.getId();
        let tc = this.state.articlethumbclips[aid] ? this.state.articlethumbclips[aid] : null;
        return (
          <Col xs={12} md={6} lg={4} key={aid}>
            <ArticleVCard data={article} thumbclip={tc} />
          </Col>
        )
      });

      // <ArticleCarousel data={this.state.articles.slice(0,3)} />
      return (
        <div className="Home">
            <ArticleCarousel articles={this.state.carouselarticles} thumbclips={this.state.carouselarticlethumbclips} />
            <Row className="mt-4"></Row>
            <Row>
                <Col xs={12} className="articlesearchcol py-4">
                    <ArticleSearchInput />
                </Col>
            </Row>
            <Row className="mt-4"></Row>
            <Row>
                {vcards}
            </Row>
            <Row>&nbsp;</Row>
            <Row>
                <Pagi tooffset={this.setOffset} offset={this.state.offset} limit={this.state.limit} count={this.state.count} />
            </Row>
        </div>
      );
    }
  }
)

export default Flow.injectFlow(Home);