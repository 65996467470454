/**
 * Article-Metadata-thumbnails to usable-URL
 */
import IsDev from './IsDev'
import placeholderxxx from '../assets/placeholder.jpg';

const ArticleThumbnail = function(articleOrThumbnail,w=800,h=600) {
    let thumb = articleOrThumbnail;
    if (articleOrThumbnail.getMetadata)
        thumb = thumb.getMetadata().get('thumbnails');

    if (thumb && thumb.indexOf(';') > -1)
        thumb = thumb.substr(0,thumb.indexOf(';'));

    if (IsDev()) {
        if (!thumb)
            return placeholderxxx;
        return `${process.env.PUBLIC_URL}/mfc/thumbnail.cgi?file=${thumb}&width=${w}&height=${h}`;
    } else {
        return `http://${window.location.hostname}/mfc/thumbnail.cgi?file=${thumb}&width=${w}&height=${h}`;
    }
}

export default ArticleThumbnail;