import './Article.scss';
import { Component } from 'react';
import { Flow } from 'flow-react';
import { observer } from "mobx-react"
// import { FormattedMessage } from 'react-intl'

import ArticleCarousel from 'Component/ArcticleCarousel';
import ArticleVCard from 'Component/ArticleVCard'
import Loading from 'Component/Loading';
import ClipHCard from 'Component/ClipHCard';
import Gallery from 'Component/Gallery';
import { Row, Col, ListGroup, Image, Container, Card, Badge, Button, Alert } from 'react-bootstrap';
import { Link, Redirect } from 'react-router-dom/cjs/react-router-dom.min';

import { useParams } from "react-router-dom";
import ArticleThumbnail from 'Helper/ArticleThumbnail';
import ClipThumbnail from 'Helper/ClipThumbnail';

import Overlay from 'Component/Overlay';

import { FormattedMessage, injectIntl } from 'react-intl'
import ArticleMap from 'Component/Map/ArticleMap';
import ClipImage from 'Component/ClipImage';
import DownloadOverlay from 'Component/DownloadOverlay';

import { FormattedDate } from 'react-intl';

import { IoImagesOutline, IoImages } from "react-icons/io5";
import IsDev from 'Helper/IsDev';
import clipGetType from 'Helper/clipGetType';
import toDate from 'Helper/toDate';
import Bundesland2Short from 'Helper/Bundesland2Short';
import { toHaveStyle } from '@testing-library/jest-dom/dist/matchers';

const Article = observer(
  class Article extends Component {
    state={
        currentuser: false,
        data: false,
        clips: false,
        comments: {},
        pictures: false,
        videos: false,
        id: false,
        olayHide: true,
        olayClip: null,
        thumbclip: null,
        downloads: [],
        logedin: false,
        redirect: false,
        dloverlay_hide: true,
        dloverlay_file: null,
        dloverlay_name: null,
        dloverlay_clip: null,
        ispublic: false,
    }

    constructor(props) {
      super(props);
      this.state.id = this.props.match.params.id;
    }

    componentDidMount() {
      // Loggedin?
      this.props.flow.getCurrentUser().then(r=>{
          this.setState({currentuser: r, logedin: true},this.loadData);
      }).catch(e=>{
          this.loadData();
      });
      
    }

    loadData() {
        // Get Project
      this.props.flow.getDistprojectById(this.state.id).then(article=>{
        let am = article.getMetadata();
        let isin = false;
        let isout = false;
        let allowedusers = am.get('allowedusers');
        let ispublic = ['publicguests','public'].includes(am.get('publishstatus'));
        // allowedusers
        if (allowedusers && allowedusers.split) {
            allowedusers = allowedusers.split(';');
            if (allowedusers.length > 0) {
                if (this.state.currentuser && allowedusers.includes(this.state.currentuser.getId()))
                    isin = true;
            }
        }

        if (!isout && !isin) {
            if (ispublic) {
                // public
                isin = true;
            } else if (['members'].includes(am.get('publishstatus'))) {
                // members
                if (this.state.logedin) 
                    isin = true;
                else
                    isout = true;
            } else {
                // private (& ?)
                isout = true;
            }
        }

        if (isout || !isin) {
            let to = '/login';
            if (this.state.currentuser)
                to = '/';
            else if (this.state.id)
                to += '/'+this.state.id;
            this.setState({redirect: to});
        } else {
            // Get Clips
            article.getClips().getAll().then(clips=>{            
                // find article thumb-clip
                let newthumbclip = null;
                if (!this.state.thumbclip) {
                clips.every(clip=>{
                    if (clip.getMetadata().get('txt5') == 'ja') {
                        newthumbclip = clip;
                        return false;
                    }
                    return true;
                });
                }
                // Get Comments
                let clipids = clips.map(c=>{ return c.getId(); });
                // this.props.flow.getComments({clipid:clipids.join(),project:this.state.id}) // Ignore project id for this fronend
                this.props.flow.getComments({clipid:clipids.join()})
                .then(res=>res.comment.asArray())
                .then(comments=>{
                    let commasobj = {};                
                    for (let c of comments) {
                    let cid = c.getClipid();
                    if (typeof commasobj[cid] === 'undefined')
                        commasobj[cid] = [];
                    commasobj[cid].push(c);
                    }

                    clips = clips.filter(c => {
                        return ['publicguests','public'].includes(c.getMetadata().get('txt1'));
                    });

                    // Set State
                    this.setState({
                    data: article,
                    clips: clips,
                    comments: commasobj,
                    thumbclip: newthumbclip,
                    ispublic: ispublic,
                    },this.articleAvailable);

                })
            })

        }

      });
    }

    articleAvailable = ()=>{
        this.getDownloads();
    }

    getDownloads = ()=>{
        this.props.flow.vifograUserGetdownloads(this.state.id,this.state.data.getMetadata().get('customid'),this.state.data.getMetadata().get('ziplink'))
            .then(res=>{
                if (res.success) {
                    // res.files = ['fotos.zip', 'videos.zip'];
                    // let files = res.files.map(f=>{ return this.state.data.getMetadata().get('ziplink')+'_'+f; });
                    this.setState({downloads: res.files}); 
                }
            });
    }

    clipClick = (clip)=>{
      this.setState({
        olayClip: clip,
        olayHide: false
      });
    }

    downloadZip = (name)=>{
      let cid = this.state.data.getMetadata().get('customid');
      let ziplink = this.state.data.getMetadata().get('ziplink')
      let id = this.state.data.getId();

      /*
      this.props.flow.getCurrentUser().then(user=>{
          window.flow.vifogradlreportAdd({ userid: user.getId(), filename:name+'.zip', articleid: id });
          // clipid
      });
      */

      let dlfile = IsDev() ? `https://vifogra.flowcenter.de/zips/articles/${id}_${cid}/${ziplink}_${name}.zip` : `${process.env.PUBLIC_URL}/zips/articles/${id}_${cid}/${ziplink}_${name}.zip`;

      this.setState({dloverlay_hide: false, dloverlay_name:name+'.zip', dloverlay_clip: null, dloverlay_file: dlfile});
      // window.open(`https://vifogra.flowcenter.de/zips/articles/${id}_${cid}/${name}.zip`, '_blank');
    }

    directVideoDownloadClick = (clip)=>{
        this.setState({olayClip:clip},this.olayDownloadClipClick);
    }

    downloadImages = ()=>{ this.downloadZip('fotos'); }
    downloadVideos = ()=>{ this.downloadZip('videos'); }

    olayDownloadClipClick = ()=>{
        let dlSourcefile =()=>{
            this.state.olayClip.getMediafiles().getAll().then(mfs=>{
                let dlmf = null;
                mfs.some(mf=>{ dlmf = mf; return mf.getSourcefile(); });
                this.setState({dloverlay_hide: false, dloverlay_name:this.state.olayClip.getTitle(), dloverlay_clip: this.state.olayClip, dloverlay_clip: this.state.olayClip, dloverlay_file: dlmf.getUrl()});
            });
        };

        if (clipGetType(this.state.olayClip) == 'picture') {
            dlSourcefile();
        } else {
            this.state.olayClip.getMediafiles().getFirstMediafileWithTag('preview').then(dlmf=>{
                this.setState({dloverlay_hide: false, dloverlay_name:this.state.olayClip.getTitle(), dloverlay_clip: this.state.olayClip, dloverlay_clip: this.state.olayClip, dloverlay_file: dlmf.getUrl()});
            }).catch(err=>{
                dlSourcefile();
            })
        }
    }

    olayPNClick = (val)=>{
        let clips = clipGetType(this.state.olayClip) == 'video' ? 
                        this.state.clips.filter(c => { return clipGetType(c) == 'video'; }) :
                        this.state.clips.filter(c => { return clipGetType(c) == 'picture'; }) ;
        let i = clips.indexOf(this.state.olayClip);

        i += val;

        if (i > clips.length-1)
            i = 0;
        else if (i < 0)
            i = clips.length-1;

        this.setState({olayClip: clips[i]});
    }

    olayNextClick = ()=>{
        this.olayPNClick(1);
    }

    olayPrevClick = ()=>{
        this.olayPNClick(-1);
    }

    dloverlay_close = ()=>{
        this.setState({dloverlay_hide: true});
    }

    render() {
      if (!!this.state.redirect)
        return <Redirect to={this.state.redirect} />

      if (!this.state.data)
        return <Loading />;

      if (!this.state.ispublic && !this.state.logedin)
        return <><h4 className='my-5'><FormattedMessage id="article.needlogin" /></h4><br/></>


      const a = this.state.data;
      const clips = this.state.clips;

      const pictures = clips.filter(c => {
          if (clipGetType(c) != 'picture')
            return false;

          return ['publicguests','public'].includes(c.getMetadata().get('txt1'));
       });
      
      const videos = clips.filter(c => { 
        if (clipGetType(c) !== 'video')
            return false;

        return ['publicguests','public'].includes(c.getMetadata().get('txt1'));
      });

      const m = a.getMetadata();
      const athumb = ArticleThumbnail(a);
      // <Image className="mx-auto" src={athumb} alt="Image" rounded fluid />

      if (this.props.setBg)
        this.props.setBg(athumb);

      /*
      const firstcthumb = pictures.length > 0 ? ClipThumbnail(pictures[0],510,385) : athumb;
      */

      let ncliphcards = {
        'Schnittbilder': {title: this.props.intl.formatMessage({id:'article.videos.schnittbilder'}), clips:[]},
        'OToene': {title: this.props.intl.formatMessage({id:'article.videos.otoene'}), clips:[]},
        'VoxPops': {title:'VoxPops', clips:[]},
        'Luftaufnahmen': {title:'Luftaufnahmen', clips:[]},
        'GoPro-Aufnahmen': {title:'GoPro-Aufnahmen', clips:[]},
        'Szenische-Aufnahmen': {title:'Szenische-Aufnahmen', clips:[]},
        'Amateurmaterial': {title:'Amateurmaterial', clips:[]},
        'Schnittbilder & O-Ton': {title:'Schnittbilder & O-Ton' , clips:[]},
        'Schnittbilder & O-Töne': {title:'Schnittbilder & O-Töne' , clips:[]},
        'Schnittbilder & VoxPop': {title:'Schnittbilder & VoxPop' , clips:[]},
        'Schnittbilder & VoxPops': {title:'Schnittbilder & VoxPops' , clips:[]},
        'O-Ton': {title:'O-Ton' , clips:[]},
        'VoxPop': {title:'VoxPop' , clips:[]},
        'Pressekonferenz': {title:'Pressekonferenz' , clips:[]},
        'Archivmaterial': {title:'Archivmaterial' , clips:[]},
      };

      let cliphcards = [];
      let cliphcardstitle = '';

      if (this.state.logedin) {
        videos.forEach((clip,i)=>{
            let tf = 'Schnittbilder'; // clip.getMetadata().get('txt8');
            if (!ncliphcards[tf]) {
                if (!ncliphcards['Video'])
                    ncliphcards['Video'] = {title:'Video' , clips:[clip]};
                else
                    ncliphcards['Video'].clips.push(clip);
            } else {
                ncliphcards[tf].clips.push(clip);
            }
        });

        for (let tf in ncliphcards) {
            let ncc = ncliphcards[tf];
            if (ncc.clips.length > 0) {
                ncc.clips.sort((a, b) => { return b.getId()-a.getId(); });
                cliphcards.push(
                  <>
                    { /* <Row><h4>{ncc.title}</h4></Row> */ }
                    <Row>
                        { ncc.clips.map(clip=>{
                            let comms = this.state.comments[clip.getId()] ? this.state.comments[clip.getId()] : [];
                            return <Col xs="12" lg="12" className="mt-2" key={clip.getId()}>
                                       <ClipHCard data={clip} comments={comms} xs={12} directDownloadClick={()=> { this.directVideoDownloadClick(clip); }} onVideoClick={()=>{ this.clipClick(clip); }}>{clip.getTitle()}</ClipHCard>
                                   </Col>;
                          })
                        }
                    </Row>
                  </>
                );
            }
        }

        /*
        cliphcardstitle = this.props.intl.formatMessage({id:'article.videos.schnittbilder'});
        if (ncliphcards.OToene.clips.length > 0)
            cliphcardstitle = this.props.intl.formatMessage({id:'article.videos.schnittuotoene'});
        */
        cliphcardstitle = this.props.intl.formatMessage({id:'article.videos.schnittuotoene'});

          /*
               <>
                  <Row>
                    <h4>{cliphcardstitle}</h4>
                  </Row>
                  <Row className="mt-2"> {cliphcards} </Row>
                </>
            */
      }

      let photothumbs = pictures.map((clip,i)=>{
        // <Image onClick={()=>{this.clipClick(clip);}} className="mx-auto mb-2" src={ClipThumbnail(clip,240,180)} alt="Image" thumbnail fluid /> 
        return (
          <Col className='cphotothumb' xs={6} md={4} lg={3} xl={2} key={clip.getId()} onClick={()=>{this.clipClick(clip);}} >
            <ClipImage className="mx-auto mb-2" force169={true} thumbmode={true} clip={clip} width={185} height={104} noplaceholder fluid/> 
          </Col>
        );
      })

      // OSM https://github.com/jperelli/osm-static-maps

      let country = m.get('country') ? m.get('country').substr(0,3).toUpperCase() : '';
      let bula = Bundesland2Short(m.get('state'));

      let newstype = m.get('newstype');

      let showmap = /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/.test(m.get('geocoords')); // 48.55890,11.28202

      let hasdate = !!m.get('eventstart');

      return (
        <>
        <Overlay
            hide={this.state.olayHide} 
            clip={this.state.olayClip} 
            nextClick={this.olayNextClick} 
            prevClick={this.olayPrevClick}
            downloadClipClick={this.olayDownloadClipClick}
            downloadPicturesClip={this.state.downloads.includes('fotos.zip') ? this.downloadImages : null}
            downloadVideosClick={this.state.downloads.includes('videos.zip') ? this.downloadVideos : null}
            downloadAll={this.state.downloads.length > 0}
            fresh={true} 
            project={a} />
        <DownloadOverlay 
            article={this.state.data}
            hide={this.state.dloverlay_hide}
            name={this.state.dloverlay_name}
            file={this.state.dloverlay_file} 
            clip={this.state.dloverlay_clip}
            close={this.dloverlay_close}
            />
        <Container className="Article mx-auto px-3">
          <Row>
            <Col xs={12} lg={this.state.logedin ? 10 : 12}>
                { this.state.logedin && 
                    <>
                        { m.get('breakingnews') == '1' && 
                          <>
                          <div className='breakingnews'><FormattedMessage id="article.breakingnews" /></div>
                          </>
                        }
                        { m.get('newstype') && m.get('newstype').length && 
                          <>
                          <div className='newstype'>{newstype.charAt(0).toUpperCase() + newstype.slice(1)}</div>
                          <div>&nbsp;</div>
                          </>
                        }
                    </>
                }
              <Row> 
                  <Col>
                    <Badge variant="dark biggerbadge">ID {a.getId()}</Badge> 
                    <span className='mx-1'></span>
                    <span className="date text-nowrap overflow-hidden">{ hasdate && <FormattedDate value={toDate(m.get('eventstart'))} year="numeric" month="2-digit" day="2-digit" /> }</span>
                    { hasdate && <span className='mx-1'>|</span> }
                    <span className="geo text-right text-nowrap overflow-hidden">
                      {country} 
                      { bula && 
                        <>
                          <span className='mx-1'>|</span>
                          <span>{bula}</span>
                        </>
                      }
                      { !!country.length && m.get('location') && ' | '}
                      {m.get('location')}
                    </span>
                  </Col>
              </Row>
              <Row>&nbsp;</Row>
              <Row className="tlA"> <Col>{m.get('title')}</Col> </Row>
              <Row className='tlB'> <Col>{m.get('kicker')}</Col> </Row>
              <Row className='tlC'> <Col>{m.get('subheadline')}</Col> </Row>
              <Row> &nbsp; </Row>
              <Row>
                <Col xs={12}>
                  <div className='tlD' dangerouslySetInnerHTML={{__html: m.get('shortdesc')}} />
                </Col>
              </Row>
              <Row>
                <Col>
                    <ClipImage className="mx-auto mb-2" thumbmode={false} clip={this.state.thumbclip} width={1250} height={700} thumbnail={false} fluid/> 
                </Col>
              </Row>
              <Row className="mt-2">
                <Col xs={12}>
                  <div dangerouslySetInnerHTML={{__html: m.get('longdesc')}} />
                </Col>
              </Row>
              <Row> &nbsp; </Row>
              { cliphcards.length > 0 && 
                <>
                    <Row><h4>{cliphcardstitle}</h4></Row>
                    {cliphcards }
                </>
              }
              { /*cliphcards.length > 0 && 
                <>
                  <Row>
                    <h4>{cliphcardstitle}</h4>
                  </Row>
                  <Row className="mt-2"> {cliphcards} </Row>
                </>
              */ }
              { this.state.logedin && photothumbs.length > 0 &&
                <>
                    <Row>
                    <h4><FormattedMessage id="article.photos" /></h4>
                    </Row>
                    <Row className="mt-2 text-center"> {photothumbs} </Row>
                </>
              }
              { /*
              <Row>
                <Col xs={12}>
                  <Gallery clips={pictures} />
                </Col>
              </Row> */
              }
            </Col>
            { this.state.logedin && 
                <Col xs={12} md={12} lg={2} className="bg-dark text-light sidebar">
                <Row className="position-sticky" style={{top: '50px'}}>
                    <Col xs={12} className="mt-3">
                    { this.state.logedin && this.state.downloads.length > 0 &&
                        <Row>
                        { pictures.length > 0 &&
                                <Col xs={12} className='my-1'>
                                    <h5><FormattedMessage id="article.gallery" values={{num: pictures.length}} /></h5>
                                    <ClipImage onClick={()=>{ this.clipClick(pictures[0]); }} className="mx-auto gallery-image" thumbmode={true} clip={pictures[0]} width={240} height={180} thumbnail={false} fluid/> 
                                    <IoImagesOutline className='gallery-icon' />
                                </Col>
                        }
                        { this.state.downloads.includes('fotos.zip') &&
                                <Col xs={12} className='my-1 mb-5'>
                                    <Button onClick={this.downloadImages} className="w-100" variant="secondary"><FormattedMessage id="article.download.pictures.all" values={{num: pictures.length}} /></Button>
                                </Col>
                        }
                        { videos.length > 0 &&
                                <Col xs={12} className='my-1'>
                                    <h5><FormattedMessage id="article.videos" values={{num: videos.length}} /></h5>
                                    <ClipImage onClick={()=>{ this.clipClick(videos[0]); }} className="mx-auto gallery-image" thumbmode={true} clip={videos[0]} width={240} height={180} thumbnail={false} fluid/> 
                                    <IoImages className='gallery-icon' />
                                </Col>
                        }
                        { this.state.downloads.includes('videos.zip') &&
                                <Col xs={12} className='my-1'>
                                    <Button onClick={this.downloadVideos} className="w-100" variant="secondary"><FormattedMessage id="article.download.videos.all" values={{num: videos.length}} /></Button> 
                                </Col>
                        }
                        </Row>
                    }
                    </Col>
                    { showmap &&
                    <Col xs={12} className="mt-3">
                        <h5><FormattedMessage id="article.location" /></h5>
                        <ArticleMap article={this.state.data} />
                    </Col>
                    }
                    { m.get('extlinks') && m.get('extlinks').length > 2 &&
                    <Col xs={12} className="mt-5">
                        <h5><FormattedMessage id="article.addlinks" /></h5>
                        <div dangerouslySetInnerHTML={{__html: m.get('extlinks')}} />
                    </Col>
                    }
                </Row>
                </Col>
            }  
          </Row>

        </Container>
        </>
      );
    }
  }
)

export default injectIntl(Flow.injectFlow(Article));