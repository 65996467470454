import { Component } from 'react';
import { Flow } from 'flow-react';
import { observer } from "mobx-react"

import { FormattedMessage } from 'react-intl'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
//  Link
} from "react-router-dom";
import { Container, Spinner, Row, Col, Button, Alert } from 'react-bootstrap';
import { IoAlertCircleOutline, IoReload } from 'react-icons/io5';

import CookieInfo from 'Component/CookieInfo';

import Home from 'Page/Home';
import Article from 'Page/Article';
import Login from 'Page/Login';
import Register from 'Page/Register';
import Legal from 'Page/Legal';
import Privacy from 'Page/Privacy';
import Search from 'Page/Search';
import Forgotpass from 'Page/Forgotpass';

import Header from 'Component/Header';
import Footer from 'Component/Footer';
import Loading from 'Component/Loading';

import './App.scss';
import Recoverpass from 'Page/Recoverpass';

const App = observer(
  class App extends Component {
    state = {
      flowconnected: false,
      reload: false,
      flowclosed: false,
      // bgImage: 'http://vifogra.flowcenter.de/mfc/thumbnail.cgi?file=/flowmediastore/sources/Vifogra/testpic_029.jpg&width=800&height=600'
    }

    componentDidMount() {
      window.flow = this.props.flow;
      this.props.flow.init().then(()=>{
          this.props.flow.sessionLogin().then(res=>{
            this.setState({flowconnected: true});
          });
      });
      this.props.flow.addEventListener(window.felib.main.event.LOGOUT,this.flowLoggedOut.bind(this));
      this.props.flow.addEventListener(window.felib.main.event.CLOSE,this.flowClosed.bind(this));
    }

    flowLoggedOut() {
        this.setState({user:false});
        document.cookie = 'ses=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        document.cookie = 'ses.sig=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        document.location.reload();
    }

    flowClosed() {
        this.setState({flowclosed: true});
    }

    clickReload(ev) {
      let id = this.props.id ? this.props.id : this.props.data.getId();
      this.props.flow.getClipById(id);
    }

    setBg = (url) => {
      this.setState({bgImage: url});
    }

    render() {
      if (this.state.flowclosed) {
          let cstyle={
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: '75vh',
              };
          return <Container style={cstyle}>
              <Row>
                <Alert variant='danger'>
                    <strong><IoAlertCircleOutline /></strong> <FormattedMessage id="app.disconnected" />
                </Alert>
              </Row>
              <Row className='mt-4'>
                <Button variant="primary" className="w-100" onClick={()=>{window.location.reload()}}> <IoReload /> <FormattedMessage id="app.reload" /> </Button>
              </Row>
          </Container>
      }

      // const basename = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development')  ? '' : '/frontend_test';
      const basename = process.env.PUBLIC_URL;
      const fluid = 'xl';
      // const fluid = 'xs';
      const setBg = this.setBg;
      //  <div className="AppOuter" style={{ backgroundImage: `url(${this.state.bgImage})` }}></div>
                 //  <Route exact path="/archive/:term" component={Archive} />
                 //  <Route exact path="/archive" component={Archive} />
      return (
        <>
            <CookieInfo cookies={['ses','ses.sig']} />
          { !this.state.flowconnected ? 
            <Container>
              <Col className="text-center">
                <Loading />
              </Col>
            </Container>
            :
            <Router basename={basename}>
              {/*<Container className="App" fluid={fluid}>*/}
              <Container className="App pb-2">
                {/*<Header basename={basename} fluid={fluid} />*/}
                <Header basename={basename} />
                <div className="mt-2" />
                <Switch>
                  <Route exact path="/login" component={Login} />
                  <Route exact path="/login/:id" component={Login} />
                  <Route path="/logout" render={({ location }) => { return <Redirect to={{ pathname: "/", state: { reload: true } }} /> } } /> 
                  <Route exact path="/register" component={Register} />
                  <Route exact path="/forgotpass" component={Forgotpass} />
                  <Route exact path="/recoverpass/:email/:secret" component={Recoverpass} />
                  <Route exact path="/legal" component={Legal} />
                  <Route exact path="/privacy" component={Privacy} />
                  <Route exact path="/article/:id" component={Article} />
                  <Route exact path="/search/:area/:term?" component={Search} />
                  <Route exact path="/home" component={Home} />
                  <Route path="/" component={() => <Home offset={0} />} />
                </Switch>
              </Container>
              {/*<Footer basename={basename} fluid={fluid} />*/}
              <Footer basename={basename} />
            </Router>
          }
        </>
    
      );
    }
  }
)

export default Flow.injectFlow(App);