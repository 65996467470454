import './DownloadOverlay.scss';
import { IoCloseCircleOutline } from "react-icons/io5";

import { Component } from 'react';
import { Flow } from 'flow-react';
import { observer } from "mobx-react"

import ClipThumbnail from 'Helper/ClipThumbnail';
import { Row, Col, ListGroup, Image, Container, Card, Badge, Button, Alert, Form } from 'react-bootstrap';
import VideoPlayer from './VideoPlayer';
import ClipImage from './ClipImage';
import { FormattedMessage, injectIntl } from 'react-intl'

const DownloadOverlay = observer(
    class DownloadOverlay extends Component {
      state={
          hide: false,
          file: '',
          name: '',
          fdata: {contact:'',phone:'',usage:''},
          ferror: [],
        }
  
      constructor(props) {
        super(props);
  
        if (props.hide)
            this.state.hide = props.hide;
        if (props.clip)
            this.state.clip = props.clip;
        if (props.file)
            this.state.file = this.fixFile(props.file);
        if (props.name)
            this.state.name = props.name;
      }

      componentWillReceiveProps(props) {
        let p = {...props};
        p.file = this.fixFile(p.file);
        this.setState(p);
      }

      fixFile(file) {
        try {
            if (file) {
                let match = /(.*medialink\/[^/]{1,}\/)(d)([a-f0-9]+\/.+)/.exec(file);
                if (match && match[3])
                    return match[1]+match[3];
            }
        } catch (e) {}
        return file;
      }

      formChange = e => {
        let nfdata = this.state.fdata;
        nfdata[e.target.getAttribute('name')] = e.target.value.trim();
        this.setState({fdata: nfdata});
      }

      cancelClick = () => {
          this.setState({file: null},this.close);
      }

      close = () => {
          if (this.props.close)
            this.props.close();
      }

      downloadClick = (event) => {
          let nerr = [];
          ['contact','phone','usage'].forEach(n=>{
            if (!this.state.fdata[n] || this.state.fdata[n].length < 1)
                nerr.push(n);
          });
          this.setState({ferror: nerr});

          if (nerr.length > 0)
            event.preventDefault();
          else {
            let aid = this.props.article ? this.props.article.getId() : null;
            let cid = this.props.clip ? this.props.clip.getId() : null;

            this.props.flow.getCurrentUser().then(user=>{
                let p = {
                    userid: user.getId(), 
                    filename: this.props.clip ? this.props.clip.getTitle() : /[^/]*$/.exec(this.state.file)[0],
                    articleid: aid,
                    clipid: cid,
                    contactname: this.state.fdata.contact,
                    contactphone: this.state.fdata.phone,
                    purpose: this.state.fdata.usage,
                };

                if (this.state.clip)
                    p.clip = this.state.clip.getId();
/*
                this.props.flow.vifogradlreportAdd(p).then(()=>{
                    // console.log('Download reported');
                });
*/
                this.props.flow.vifogradlreportAdd(p).then(()=>{
                    let link = document.createElement('a');
                    link.href = this.state.file;
                    /* link.target = '_blank'; */
                    link.download = this.state.clip ? this.state.clip.getTitle() : '';
                    link.click();
                });
            });

            this.setState({file: null},this.close);
          }
      }
  
      render() {

        /*
        <Col xs={12} md={6}>
            <a href={this.state.file} target="_blank" download={this.state.clip ? this.state.clip.getTitle() : ''} onClick={this.downloadClick}>
                <Button variant="primary">
                    <FormattedMessage id="download.download" />
                </Button>
            </a>
        </Col>
            */
        return (
          <div className={`DownloadOverlay ${this.state.hide ? 'hide' : 'show'}`}>
              <Row className='mx-2'>
                <Col style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <Container>
                    <Row className='dlheader'>
                        <Col>
                            <b><FormattedMessage id="download.download" /></b> {this.state.name}
                        </Col>
                    </Row>
                    <Row>
                        <Form className="dlform">

                            <Form.Group controlId="contact">
                                <Form.Label><FormattedMessage id="download.contact" /></Form.Label>
                                <Form.Control name="contact" type="text" onChange={this.formChange}/>
                                { this.state.ferror.includes('contact') &&
                                    <Form.Text className="text-danger"><FormattedMessage id="download.error.contact" /></Form.Text>
                                }
                            </Form.Group>

                            <Form.Group controlId="phone">
                                <Form.Label><FormattedMessage id="download.phone" /></Form.Label>
                                <Form.Control name="phone" type="text" onChange={this.formChange}/>
                                { this.state.ferror.includes('phone') &&
                                    <Form.Text className="text-danger"><FormattedMessage id="download.error.phone" /></Form.Text>
                                }
                            </Form.Group>

                            <Form.Group controlId="usage">
                                <Form.Label><FormattedMessage id="download.usage" /></Form.Label>
                                <Form.Control as="textarea" name="usage" rows={3} onChange={this.formChange} />
                                { this.state.ferror.includes('usage') &&
                                    <Form.Text className="text-danger"><FormattedMessage id="download.error.usage" /></Form.Text>
                                }
                            </Form.Group>

                            <Form.Text muted><FormattedMessage id="download.help" /></Form.Text>
                            <Row>&nbsp;</Row>
                            <Row>
                                <Col xs={12} md={6}>
                                    <Button variant="primary" onClick={this.downloadClick}>
                                        <FormattedMessage id="download.download" />
                                    </Button>
                                </Col>
                                <Col xs={12} md={6} className='text-right'>
                                    <Button variant="secondary" onClick={this.cancelClick}>
                                        <FormattedMessage id="download.cancel" />
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </Row>
                    </Container>
                </Col>
              </Row>
          </div>
        );

      }

    }
  );
  
  export default Flow.injectFlow(DownloadOverlay);