// import Container from 'react-bootstrap/Container';
import './Footer.scss';
import { Row, Col, Container } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { FormattedMessage } from 'react-intl'
import IsDev from 'Helper/IsDev';

import {IoLogoFacebook, IoLogoInstagram, IoLogoTwitter} from "react-icons/io5";
  
// <Container fluid={props.fluid} className="mt-2 Footer bg-dark text-white"></Container>
function Footer(props) {
    return (
        <Container className="mt-2 Footer text-white bg-dark">
            <Row className="pt-2">
                <Col xs={12} sm={6}>
                    <p className="text-muted">
                        Vifogra GmbH<br/>
                        <a href="mailto:newsdesk@vifogra.com">newsdesk@vifogra.com</a><br/>
                    </p>
                    <div className="mt-2 text-muted"><FormattedMessage id="footer.byflowworks" /></div>
                    <div className='mb-3'><a href="/uploader" target="_blank"><FormattedMessage id="footer.touploader" /></a></div>
                </Col>
                <Col xs={12} sm={6}>
                    <Row>
                        <Col className="text-right">
                            <div><a href="https://jobs.vifogra.com/" target="_blank"><FormattedMessage id="footer.career" /></a></div>
                            <div><Link to="/legal"><FormattedMessage id="footer.legal" /></Link></div>
                            <div><Link to="/privacy"><FormattedMessage id="footer.privacy" /></Link></div>
                            <div className='socialicons'>
                                <a href="https://www.facebook.com/vifogra" target='_blank' ><IoLogoFacebook /></a>
                                <a href="https://www.instagram.com/nachrichtenagentur_vifogra/" target='_blank' ><IoLogoInstagram /></a>
                                <a href="https://twitter.com/vifogra" target='_blank' ><IoLogoTwitter /></a>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
            { IsDev() && 
                <Row>
                    <Col xs={12} style={{color: 'red', fontWeight: 'bold'}}>
                        <div className="d-block d-sm-none">xs</div>
                        <div className="d-none d-sm-block d-md-none">sm</div>
                        <div className="d-none d-md-block d-lg-none">md</div>
                        <div className="d-none d-lg-block d-xl-none">lg</div>
                        <div className="d-none d-xl-block d-xxl-none">xl</div>
                        <div className="d-none d-xxl-block d-xxxl-none">xxl</div>
                        <div className="d-none d-xxxl-block">xxxl</div>
                    </Col>
                </Row>
            }
        </Container>
    )
}

export default Footer;
