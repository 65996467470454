import  { Navbar, Nav, Container, Row, Col, Form, FormControl, Button, NavDropdown } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl'
import { Link } from "react-router-dom";
import { Component } from 'react';
import { Flow } from 'flow-react';
import { observer } from "mobx-react"
// import imageFile from './assets/image.png';
import logo from '../assets/logo.svg';
import banner from '../assets/banner.jpg';

import { IoSearch, IoLogInOutline, IoLogOutOutline, IoHome, IoPencilSharp, IoNewspaperOutline, IoChatboxEllipsesOutline, IoEyeOutline, IoBagHandleOutline, IoCloudOutline, IoHourglassOutline } from "react-icons/io5";

import './Header.scss';

const Header = observer(
  class Header extends Component {
    state = {
        issticky: false,
        user: false
    }
    toplogoheight = 100;

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll.bind(this));
        this.props.flow.addPostLoginFunction(this.flowPostLogin.bind(this));

        // Additional Loggedin test?
        window.flow.getCurrentUser().then(user=>{
            this.setState({user: user});
        }).catch(e=>{});
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll.bind(this));
    }

    handleScroll(ev) {
        this.setState({issticky: !!(window.pageYOffset > this.toplogoheight)});
    }

    flowPostLogin = (user) => {
        this.setState({user:user});
    }

    logoutClick = () => {
        this.props.flow.logout();
    }

/*
    <Col xs={12} sm={3}>
        <img
        src={`${this.props.basename}/logo.svg`}
        height={this.toplogoheight}
        className="d-inline-block align-top"
        alt="Vifogra"
        />
    </Col>
    /////
    <Col xs={12} sm={9} className="text-right text-muted"><FormattedMessage id="header.tagline" /></Col>
*/

    render() {
        return (
            <>
            {/*<Container className="Header" fluid={this.props.fluid}>*/}
            <Container className="Header bannerbox">
                <img
                src={banner}
                className="banner w-100"
                alt="Vifogra"
                />
{/*
                <Row className="align-items-end">
                    <Col xs={12} className='m-0 p-0'>
                        <img
                        src={banner}
                        className="d-inline-block align-top w-100"
                        alt="Vifogra"
                        />
                    </Col>
                </Row>
*/}
            </Container>
            <Navbar collapseOnSelect className={`Header ${this.state.issticky?'sticky':'nosticky'}`} sticky="top" expand="lg" bg="dark" variant="dark" style={{marginRight: '1px'}}>
                <Navbar.Brand>
                    <Link to="">
                        <img
                        src={logo}
                        height="30"
                        alt="Vifogra"
                        className="logo"
                        style={{filter: 'invert(100%)'}}
                        />
                    </Link>
                </Navbar.Brand>
                <Navbar.Toggle />
                <Navbar.Collapse>
                    <Nav className="mr-auto">
                        <Nav.Item>
                            <Nav.Link as={Link} to="/"><IoHome /> <FormattedMessage id="header.home" /></Nav.Link>
                        </Nav.Item>
                        { this.state.user && 
                            <>
                            <Nav.Item>
                            <Nav.Link as={Link} to="/search/news/"><IoNewspaperOutline /> <FormattedMessage id="header.news" /></Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                            <Nav.Link as={Link} to="/search/stories/"><IoChatboxEllipsesOutline /> <FormattedMessage id="header.stories" /></Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                            <Nav.Link as={Link} to="/search/eyewitness/"><IoEyeOutline /> <FormattedMessage id="header.eyewitness" /></Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                            <Nav.Link as={Link} to="/search/orders/"><IoBagHandleOutline /> <FormattedMessage id="header.orders" /></Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                            <Nav.Link as={Link} to="/search/timeless/"><IoHourglassOutline /> <FormattedMessage id="header.timeless" /></Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                            <Nav.Link as={Link} to="/search/weather/"><IoCloudOutline /> <FormattedMessage id="header.weather" /></Nav.Link>
                            </Nav.Item>
                            <div className='divider'></div>
                            </>
                        }
{ /*
                        <NavDropdown bg="dark" title="Region" id="basic-nav-dropdown">
                            <NavDropdown.Item href="#"><IoLocationSharp /> Deutschland</NavDropdown.Item>
                            <NavDropdown.Item href="#"><IoLocationOutline /> Deutschsprachiger Raum</NavDropdown.Item>
                            <NavDropdown.Item href="#"><IoLocation /> Welt</NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item href="#"><IoLocateSharp /> Baden Württemberg</NavDropdown.Item>
                            <NavDropdown.Item href="#"><IoLocateSharp /> Bayern</NavDropdown.Item>
                            <NavDropdown.Item href="#"><IoLocateSharp /> Berlin</NavDropdown.Item>
                            <NavDropdown.Item href="#"><IoLocateSharp /> Brandenburg</NavDropdown.Item>
                            <NavDropdown.Item href="#"><IoLocateSharp /> Bremen</NavDropdown.Item>
                            <NavDropdown.Item href="#"><IoLocateSharp /> Hamburg</NavDropdown.Item>
                            <NavDropdown.Item href="#"><IoLocateSharp /> Hessen</NavDropdown.Item>
                            <NavDropdown.Item href="#"><IoLocateSharp /> Mecklenburg-Vorpommern</NavDropdown.Item>
                            <NavDropdown.Item href="#"><IoLocateSharp /> Niedersachsen</NavDropdown.Item>
                            <NavDropdown.Item href="#"><IoLocateSharp /> Nordrhein Westfalen</NavDropdown.Item>
                            <NavDropdown.Item href="#"><IoLocateSharp /> Rheinland-Pfalz</NavDropdown.Item>
                            <NavDropdown.Item href="#"><IoLocateSharp /> Saarland</NavDropdown.Item>
                            <NavDropdown.Item href="#"><IoLocateSharp /> Sachsen</NavDropdown.Item>
                            <NavDropdown.Item href="#"><IoLocateSharp /> Sachsen-Anhalt</NavDropdown.Item>
                            <NavDropdown.Item href="#"><IoLocateSharp /> Schleswig-Holstein</NavDropdown.Item>
                            <NavDropdown.Item href="#"><IoLocateSharp /> Thüringen</NavDropdown.Item>
                        </NavDropdown>
*/ }
                    </Nav>
                        <Nav.Item>
                        <Nav.Link as={Link} to="/search/all/"><IoSearch /> <FormattedMessage id="header.search" /></Nav.Link>
                        </Nav.Item>
                    { this.state.user ?
                        <Nav.Link>
                            <Link onClick={this.logoutClick} to="#">{this.state.user.getUsername()} <IoLogOutOutline className="icon" /> <span className="d-md-none"><FormattedMessage id="header.logout" /></span></Link>
                        </Nav.Link>
                        :
                        <>
                        <Nav.Link>
                            <Link to="/login"><IoLogInOutline className="icon" /> <FormattedMessage id="header.login" /></Link>
                        </Nav.Link>
                        </>
                    }
                </Navbar.Collapse>
            </Navbar>
            </>
        );
    }
  }
)
/*
                        <Nav.Link>
                            <Link to="/register"><IoPencilSharp className="icon" /> <FormattedMessage id="header.register" /></Link>
                        </Nav.Link>
*/

export default Flow.injectFlow(Header);