import { Component } from 'react';
import { Flow } from 'flow-react';
import { observer } from "mobx-react"
import { FormattedMessage } from 'react-intl'

import ArticleCarousel from 'Component/ArcticleCarousel';
import ArticleVCard from 'Component/ArticleVCard'
import Loading from 'Component/Loading';
import { Row, Col, Form, Button, Container, Alert } from 'react-bootstrap';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

import { Redirect } from 'react-router-dom';

const Forgotpass = observer(
  class Forgotpass extends Component {
    state = {
      done: false,
      error: false
    }

    handleSubmit = (event) => {
      const fd = new FormData(event.currentTarget);
      event.preventDefault();
      let email = fd.get('email');
      if (email.length > 5) {
        window.flow.vifograUserPassforgotten(email)
            .then(()=>{ this.setState({done: true}); })
            .catch(()=>{ this.setState({error: true}); });
      }
    }

    render() {
        if (this.state.done) {
            return (
                <>
                    <Alert variant="success"><FormattedMessage id="forgotpass.done" /></Alert>
                    <Row>&nbsp;</Row>
                </>
            );
        }

        return (
            <Container className="Forgotpass">
                <Row>
                    <Col xs={12}>
                        <h3><FormattedMessage id="forgotpass.header" /></h3>
                        <p><FormattedMessage id="forgotpass.text" /></p>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <Form onSubmit={this.handleSubmit}>
                            <Form.Group controlId="formBasicEmail">
                            {/* <Form.Label><FormattedMessage id="forgotpass.email" /></Form.Label>
                            <Form.Text className="text-muted"><FormattedMessage id="forgotpass.description" /></Form.Text> */ }
                            <Form.Control name="email" type="text" />
                            </Form.Group>

                            <Button variant="primary" type="submit"> <FormattedMessage id="forgotpass.submit" /> </Button>
                            { this.state.error && 
                                <>
                                    <Row>&nbsp;</Row>
                                    <Alert variant="danger"><FormattedMessage id="forgotpass.error" /></Alert> 
                                </>
                            }
                            <Row>&nbsp;</Row>
                        </Form>
                    </Col>
                </Row>
            </Container>
        );
    }
  }
)

export default Flow.injectFlow(Forgotpass);