import React from "react";
import felib from './felib.js';
import { makeObservable, observable, action } from "mobx"

/**
 * Helper
 */

const getDisplayName = Component => {
    return (
      Component.displayName ||
      Component.name ||
      (typeof Component === 'string' && Component.length > 0
        ? Component
        : 'Unknown')
    );
};

/**
 * React & MobX
 */

export const FlowContext = React.createContext();

/**
 * ToDo
export class FlowElement extends React.Component {
  constructor(props) {
      super(props);
  }

  render() {
    const head = React.createElement(
        'h3',
        {},
        'FlowElement'
    );

    const text = React.createElement(
        'div',
        {},
        'ToDo'
    );

    return React.createElement(
        'div',
        {className: 'FlowElement'},
        head,
        text
    );
  }
}
*/

export const injectFlow = (injectInto) => {
  class WithFlow extends React.Component {
    componentDidMount() {
      if (this.context && !this.context.flow)
        console.error('FlowProvider seems to be missing!');
    }

    render() {
        return React.createElement(
          injectInto,
          this.props.flow ? this.props : {fw:this.context.fw, flow:this.context.flow, ...this.props}
        );
    }
  }

  WithFlow.contextType = FlowContext;
  WithFlow.displayName = `WithFlow(${getDisplayName(injectInto)})`;

  return WithFlow;
}

const newFelibDataObjectWrap = (obj)=>{
  makeObservable(obj,{
    data: observable,
    addFields: action,
    unserialize: action,
    _executeValueUpdateFn: action,
  })
  return obj;
};

export const FlowProvider = (_ref) => {
    const value = {
        fw: {
          apiurl: _ref.apiurl ? _ref.apiurl : document.location.protocol+'//'+document.location.host+'/api/initws',
          config: _ref.config ? _ref.config : {},
        },
        defaultNS: _ref.defaultNS,
    };
    value.flow = new felib.main(value.fw.apiurl,value.fw.config);
    value.flow.getDataClass().addNewDataObjectFunction(newFelibDataObjectWrap);
    value.fw.flow = value.flow;

    return React.createElement(FlowContext.Provider, {
      value: value
    }, _ref.children);
}

/**
 * exports
 */

export { felib };

export const Flow = {
    FlowContext: FlowContext,
    // FlowElement: FlowElement,
    FlowProvider: FlowProvider,
    injectFlow: injectFlow,
    felib: felib,
};

export default Flow;
