import Loading from './Loading';
import { Row, Col, Card, Image } from 'react-bootstrap';
import { Component } from 'react';
import isDev from 'Helper/IsDev';
import placeholder from '../assets/placeholder.jpg';
import clipGetType from 'Helper/clipGetType';

class ClipImage extends Component {
  state={
      clip: null,
      url: null,
      thumbmode: false,
      width: 240,
      height: 180,
      className: '',
      style: {},
      thumbnail: false,
      fluid: false,
      onClick: null,
      force169: false,
      preventObjectFit: false,
  }

  constructor(props) {
    super(props);
    this.state.clip = props.clip;
    this.state.url = props.url;
    this.state.thumbmode = props.thumbmode ? props.thumbmode : false;
    this.state.className = props.className ? props.className : '';

    this.state.thumbnail = props.thumbnail ? props.thumbnail : false;
    this.state.fluid = props.fluid ? props.fluid : false;

    this.state.style = props.style ? props.style : {};

    if (props.force169 && !props.preventObjectFit) {
        // this.state.style.width = '100%';
        // this.state.style.height = '56.25%';
        this.state.style.objectFit = 'contain';
    }

    this.state.width = props.width ? props.width : 240;
    this.state.height = props.height ? props.height : 180;

    if (!this.state.url) {
      this.loadUrl();
    }
  }

  componentWillReceiveProps(props) {
    if (props.clip && (!this.state.clip || this.state.clip.getId() != props.clip.getId())) {
      this.setState({clip: props.clip, url: null},this.loadUrl);
    }
  }

  onClick = (ev) => {
      if (this.props.onClick) {
        this.props.onClick(ev);
      }
  }

  imageOnContextmenu = (e) => {
    e.preventDefault();
    return false;
  }

  loadUrl() {
    if (this.state.clip && !this.state.url) {
        if (clipGetType(this.state.clip) == 'video') { // Video
            let mt = this.state.clip.getMainthumbnail();
            let u = false;
            if (mt)
                u = mt.getUrl();
            if (u && u.includes('thumbnail.cgi'))
                u += `&width=${this.state.width}&height=${this.state.height}`;
            if (u)
                Promise.resolve().then(()=>{ this.setState({url: u}); });
        } else if (clipGetType(this.state.clip) == 'picture') { // Image
            this.state.clip.getMediafiles().getAll().then(mfs=>{
                mfs.every(mf=>{ 
                    if (mf.hasTag('watermark')) {
                        if (this.state.thumbmode) {
                            let u;
                            u = this.state.force169 ?
                                    `/mfc/thumbnail.cgi?file=${mf.getPath()+mf.getFilename()}&width=${this.state.width}&height=${this.state.height}` :
                                    `/mfc/thumbnail.cgi?file=${mf.getPath()+mf.getFilename()}&crop_inner_width=${this.state.width}&crop_inner_height=${this.state.height}`;
                            if (isDev())
                                u = '//vifogra.flowcenter.de'+u;
                            Promise.resolve().then(()=>{ this.setState({url: u}); });
                        } else {
                            Promise.resolve().then(()=>{ this.setState({url:mf.getUrl()}); }); 
                        }
                        return false;
                    }
                    return true;
                });
            })
        }

    }

  }

  render() {
    let src = placeholder;

    if (this.state.url) {
        src = this.state.url;
    } else {
        if (this.state.clip && clipGetType(this.state.clip) == 'video') {
            return <Image onContextMenu={this.imageOnContextmenu} onClick={this.onClick} className={'ClipImage '+this.state.className} style={this.state.style} src={process.env.PUBLIC_URL+'/img_placeholder.jpg'} alt="image" fluid={this.state.fluid} />
        }
        return null;
    }
    
    /*else if (this.props.noplaceholder) {
        return null;
    }
    */

    return <Image onContextMenu={this.imageOnContextmenu} onClick={this.onClick} className={'ClipImage '+this.state.className} style={this.state.style} src={src} alt="image" thumbnail={this.state.thumbnail} fluid={this.state.fluid} />
  }

}

export default ClipImage;