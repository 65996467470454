import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {IntlProvider } from 'react-intl';
import IsDev from 'Helper/IsDev';
// import {IntlProvider, FormattedMessage, FormattedNumber} from 'react-intl'

// Flow
import { FlowProvider } from 'flow-react';

// Bootstrap Custom
import './custom.scss';

// I18n
// import messages_en from "lang/en.json";
import messages_de from "lang/de.json";
const messages = {
    // 'en': messages_en,
    'de': messages_de
};
let _locale = navigator.language.split(/[-_]/)[0];
const locale = !!messages[_locale] ? _locale : 'de';

const apiurl = IsDev() ? 'https://vifogra.flowcenter.de/flow2/api/initws' : process.env.PUBLIC_URL+'/flow2/api/initws';
// const apiurl = 'https://vifogra.flowcenter.de/flow2/api/initws';

ReactDOM.render(
  <React.StrictMode>
    <IntlProvider messages={messages[locale]} locale={locale} defaultLocale="de">
      <FlowProvider apiurl= {apiurl} config={{pingtime: 20,throwonfail: true}}>
        <App />
      </FlowProvider>
    </IntlProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
