// import Container from 'react-bootstrap/Container';
import { Row, Col, Container, Form, Button } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl'
import { IoSearch } from "react-icons/io5";
import { useState } from 'react';

import { Link, useHistory } from 'react-router-dom';
import {useCallback} from 'react';


function ArticleSearchInput(props) {
    const history = useHistory();
    const [term, setTerm] = useState()

    const handleOnKeyup = ev => {
        ev.preventDefault();
        if (ev.keyCode == 13)
            search();
    };

    const handleOnClick = ev => {
        ev.preventDefault();
        search();
    };

    const node = {
        input: <Form.Control size="lg" type="text" placeholder="Suche" onChange={event => setTerm(event.target.value)} onKeyUp={handleOnKeyup} />,
    }

    const search = () => {
        let s = term ? term : '';
        let area = props.area ? props.area : 'all';
        history.push('/search/'+area+'/'+encodeURIComponent(s));
        if (props.onSearch)
            props.onSearch(s);
    };

    return (
        <Form className="ArticleSearchInput">
        <Row>
            <Col sm={1} className="d-none d-sm-block"/>
            <Col xs={12} sm={8} lg={9}>
                {node.input}
            </Col>
            <Col xs={12} className="d-block d-sm-none mb-1" />
            <Col xs={12} sm={2} lg={1} className="text-right">
            <Button variant="primary" type="submit" size="lg" className="w-100" onClick={handleOnClick} ><IoSearch /></Button>
            </Col>
            <Col sm={1} className="d-none d-sm-block"/>
        </Row>
        </Form>
    )
}

export default ArticleSearchInput;





