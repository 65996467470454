/**
 * is Development?
 */

const clipGetType = function(clip) {
    if (!clip || !clip.getMedia)
        return 'unknown';

    let media = clip.getMedia();
    if (media.asString().indexOf('video') > -1)
        return 'video';

    // sourcefile extension backup
    // (clip)=>{ (await clip.getMediafiles().getAll()).forEach(mf=>{ console.log(mf.getSourcefile()); }); });
    let smf = clip.getMediafiles().elements.find(mf=>{ return !!mf.getSourcefile(); })
    if (smf) {
        let fn = smf.getFilename();
        let ext = fn.substr(fn.lastIndexOf('.') + 1);
        if (['mp4','mov','mxf'].includes(ext))
            return 'video';
    }

    if (media.has('pictures'))
        return 'picture';
    return 'unknown';
}

export default clipGetType;