import { Component } from 'react';
import { observer } from "mobx-react"
import { Container } from 'react-bootstrap';

class Privacy extends Component {
  render() {
    return (
      <Container className="Legal">
        <p>
            <h1>Impressum</h1>
            <h2>Angaben gemäß § 5 TMG</h2>

            <p>Vifogra GmbH<br/>
               Sudetenstraße 20<br/>
               86529 Schrobenhausen</p>


            <p>Handelsregister: 11522<br/>
               Registergericht: Amtsgericht Ingolstadt</p>

            <p><b>Vertreten durch:</b><br/>
               Michael Schmelzer, Uwe Friedrich</p>

            <h2>Kontakt</h2>
            <p>Telefon: +49 (0) 8252 903957-0<br/>
               Telefax: +49 (0) 8252 903957-9<br/>
               E-Mail: newsdesk@vifogra.com</p>

            <h2>Umsatzsteuer-ID</h2>
            <p>Umsatzsteuer-Identifikationsnummer gemäß § 27 a Umsatzsteuergesetz:<br/>
               DE365528659</p>

            <h2>Redaktionell verantwortlich</h2>
            <p>Annette Schmelzer<br/>
               Sudetenstraße 20<br/>
               86529 Schrobenhausen</p>

            <h2>Verbraucherstreitbeilegung/Universalschlichtungsstelle</h2>
            <p>Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>
        </p>
      </Container>
    );
  }
}

export default Privacy;
