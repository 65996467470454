import { Component } from 'react';
import { Flow } from 'flow-react';
import { observer } from "mobx-react"
import { FormattedMessage } from 'react-intl'

import ArticleCarousel from 'Component/ArcticleCarousel';
import ArticleVCard from 'Component/ArticleVCard'
import Loading from 'Component/Loading';
import { Row, Col, Form, Button } from 'react-bootstrap';

import './Home.scss';
import { IoSearch } from 'react-icons/io5';
import ArticleSearchInput from 'Component/ArticleSearchInput';
import Pagi from 'Component/Pagi';
import { injectIntl } from 'react-intl';

import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';

const Search = observer(
  class Search extends Component {
    state={
        articles: false,
        articlethumbclips: [],
        area: 'all',
        term: '',
        limit: 6,
        offset: 0,
        count: 0,
        currentuser: false,
        redirect: false,
    }

    constructor(props) {
      super(props);
      if (props.match) {
        if (props.match.params.term)
            this.state.term = this.props.match.params.term;
        if (props.match.params.area)
            this.state.area = this.props.match.params.area;
      }
    }

    componentDidMount() {
      this.props.flow.getCurrentUser().then(user=>{
          this.setState({currentuser: user},this.search);
      }).catch(e=>{
          if (this.state.area !== 'all')
            this.setState({redirect: '/login'});
          else
            this.search();
      });

    }

    search() {
      let sfields = [{field: "_fulltext_", type: 'contains', value: this.state.term}];

      let adfields = [];
      if (this.state.area && this.state.area != 'all') {
        let aval = null;
        switch (this.state.area) {
            case 'news': aval = 'news'; break;
            case 'stories': aval = 'storys'; break;
            case 'eyewitness': aval = 'eyewitness'; break;
            case 'orders': aval = 'orders'; break;
            case 'timeless': aval = 'timeless'; break;
            case 'weather': aval = 'weather'; break;
            default: console.error(`Unknown area: ${this.state.area}`);
        }
        if (aval)
          adfields.push({field: 'txt31',type: '=', value: aval});
      }

      sfields = [
          ...sfields, 
          ...adfields,
          {field:'',type:'BEGIN_OR',value:''},
      ];

      if (this.state.currentuser) 
          sfields.push({"field":"allowedusers","type":"REGEXP","value":`(^|;)${this.state.currentuser.getId()}(;|$)`});

      sfields = [
          ...sfields,
          {"field":"publishstatus","type":"=","value":"members"},
          {"field":"publishstatus","type":"=","value":"public"},
          {"field":"publishstatus","type":"=","value":"publicguests"},
          {field:'',type:'END_OR',value:''},
          {field: 'publishdate',type: 'ORDER', value: 'DESC'}
        ];

      this.props.flow.getDistprojectsForDist(
          'pmdb_newstv',
          0,
          sfields,
          {limit: this.state.limit, offset: this.state.offset}).then(res=>{
            let arts = [];
            if (res.distproject)
              arts = res.distproject.asArray();
            this.setState({articles: arts,count: res.count, offset: res.offset, limit: res.limit});

            // get thumbnail clips within projects
            let fields = [{field:'txt5',type:'=',value:'ja'},
                          {field:'',type:'BEGIN_OR',value:''},
                         ];
            arts.forEach(p=>{
                fields.push({field:'projectlinkid',type:'=',value:p.getId()});
            });

            // get clips, defined as holding article thumbs
            this.props.flow.getClipsByFields([...fields, {field:'',type:'END_OR',value:''}])
                .then(cbf=>{
                    if (cbf.clip) {
                        let newarticlethumbs = {};
                        cbf.clip.asArray().forEach(clip=>{
                            let pids = clip.getProjectids();
                            if (pids && pids.length > 0) {
                                pids.forEach(pid=>{ 
                                    if (!newarticlethumbs[pid]) {
                                        newarticlethumbs[pid] = clip;
                                    }
                                });
                            }
                        });
                        this.setState({articlethumbclips: newarticlethumbs})
                    }
                });
        });
    }

    componentWillReceiveProps(props) {
        if (props.match && props.match.params.area)
            this.setState({area: props.match.params.area, articles: false, articlethumbclips: {}, offset: 0},this.search);
    }

    handleSearch = (term) => {
      this.setState({articles: false, articlethumbclips: {}, term:term, offset: 0},this.search);
    }

    setOffset = (offset)=>{
        this.setState({offset: offset, articles: false, articlethumbclips: {}},this.search);
    }

    render() {
      if (!!this.state.redirect)
        return <Redirect to={this.state.redirect} />

      if (!this.state.currentuser && this.state.area !== 'all')
        return <><h4 className='my-5'><FormattedMessage id="needlogin" /></h4><br/></>

      if (this.state.articles === false)
        return <Loading />;

      /*
      let vcards = this.state.articles.map((article,i) => {
        return (
          <Col xs={12} md={6} lg={4}>
            <ArticleVCard key={article.getId()} data={article} />
          </Col>
        )
      });
      */

      let vcards = this.state.articles.map((article,i) => {
        let aid = article.getId();
        let tc = this.state.articlethumbclips[aid] ? this.state.articlethumbclips[aid] : null;
        return (
          <Col xs={12} md={6} lg={4} key={aid}>
            <ArticleVCard data={article} thumbclip={tc} />
          </Col>
        )
      });

      let showarea = ['news','stories','eyewitness','orders','timeless','weather'].includes(this.state.area);
      let txt_area = showarea ? this.props.intl.formatMessage({id:'search.'+this.state.area}) : '';

      return (
        <div className="Search">
          { showarea &&
            <Row>
                <Col xs={12}>
                    <h2>{txt_area}</h2>
                </Col>
            </Row>
          }
          <Row>
            <Col xs={12} className="articlesearchcol py-4">
              <ArticleSearchInput term={this.state.term} onSearch={this.handleSearch} />
            </Col>
          </Row>
          <Row className="mt-2"></Row>
          { this.state.term.length > 0 && 
            <Row>
                <Col>
                    <h4 className='ml-1 mb-4'><FormattedMessage id="archive.resultsForTerm" values={{term: this.state.term, num: this.state.count}} /></h4>
                </Col>
            </Row>
          }
          <Row>
            { this.state.articles.length < 1 ?
              <Col><FormattedMessage id="archive.noResults" /></Col>
              :
              vcards
            }
          </Row>
          <Row>&nbsp;</Row>
          <Row>
              <Pagi tooffset={this.setOffset} offset={this.state.offset} limit={this.state.limit} count={this.state.count} />
          </Row>
        </div>
      );
    }
  }
)

export default injectIntl(Flow.injectFlow(Search));