import './VideoPlayer.scss';
import React from 'react'
import { IoCloseCircleOutline } from "react-icons/io5";

import { Component } from 'react';
import { Flow } from 'flow-react';
import { observer } from "mobx-react"

import ClipThumbnail from 'Helper/ClipThumbnail';
import { Row, Col, ListGroup, Image, Container, Card, Badge, Button, Alert } from 'react-bootstrap';

const VideoPlayer = observer(
    class VideoPlayer extends Component {
      state={
          clip: null,
          project: null,
          src: null,
          comments: [],
          currentPlayerTime: 0,
        }

      constructor(props) {
        super(props);
        this.video = React.createRef();

        // data from props or get via id
        if (props.clip)
          this.state.clip = props.clip;
        if (props.project)
          this.state.project = props.project;
        this.loadData();
      }

      componentWillReceiveProps(props) {
        // clip project
        if (props.clip) {
          this.setState({clip: props.clip, project: props.project, src: null, comments: [], currentPlayerTime: 0},this.loadData);
        }

      }

      loadData() {
        if (!this.state.clip) {
          console.error('No Clip');
          return;
        }

        this.state.clip.getMediafiles().getFirstMediafileWithTag(['watermark','preview'])
            .then(mf=>{
                this.setState({
                  src: mf.getUrl(),
                  comments: [],
                })
            }).catch(e=>{
                this.state.clip.getMediafiles().getFirstMediafileWithExtension(['mp4'])
                    .then(mf=>{
                        this.setState({
                          src: mf.getUrl(),
                          comments: [],
                        })
                    }).catch(e=>{
                        console.error(`No Mediafile ['watermark','preview'] ['mp4'] available.`);
                    });
            });
      }

            /*
            this.props.flow.getComments({clipid:this.state.clip.getId()})
              .then(res=>res.comment.asArray())
              .then(comments=>{
                comments.sort((a,b)=>{ let r=a.getTcin()-b.getTcin(); if (r==0) return a.getTcout()-b.getTcout(); return r; });
                this.setState({
                  src: mf.getUrl(),
                  comments: comments
                });
              })
            */

      lastontimeupdate = 0;
      ontimeupdate = (ev)=>{
        if (!ev.target.currentTime)
          return;

        let currenttimeupdate = +(new Date());
        if ((currenttimeupdate - this.lastontimeupdate) < 1000)
          return;
        this.lastontimeupdate = currenttimeupdate;

        this.setState({currentPlayerTime:ev.target.currentTime});
      };

      scrollTo = null;
      componentDidUpdate() {
        if (this.video.current) {
            this.video.current.oncontextmenu = (event) => {
                event.preventDefault();
            }
        }

        if (this.scrollTo) {
          if (this.scrollTo.scrollIntoViewIfNeeded)
            this.scrollTo.scrollIntoViewIfNeeded({behavior: 'smooth'});
          else if (this.scrollTo.scrollIntoView)
            this.scrollTo.scrollIntoView({behavior: 'smooth'});
          this.scrollTo = null;
        }
      };

      render() {
        if (!this.state.src) {
          return null;
        }

        let c = this.state.clip;
        let src = this.state.src;


        let licomments = this.state.comments.map(com=>{
          let tin = parseFloat(com.getTcin());
          let tout = parseFloat(com.getTcout());
          let variant = 'light';

          if (this.state.currentPlayerTime > tin) {
            if ((this.state.currentPlayerTime < tout) ||
                (tin == tout && this.state.currentPlayerTime < tin+5)
               ) {
              variant = 'primary';
            }
          }

          let r = (
            <li key={com.getId()}>
              <Badge variant={variant}>{tin}-{tout}</Badge>
              <span> &nbsp; {com.getComment()}</span>
            </li>
          );

          if (variant == 'primary')
            this.scrollTo = r;
          return r;
        });

        return (
          <div className="VideoPlayer">
            <div className="player">
              <video ref={this.video} width="320" height="240" controls className="embed-responsive embed-responsive-21by9" controlsList="nodownload" onTimeUpdate={this.ontimeupdate}>
                <source src={src} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
            { /*
            <div className="comments">
              <ul>{licomments}</ul>
            </div>
            */ }
          </div>
        );
      }

    }
  );

  export default Flow.injectFlow(VideoPlayer);
