import { Component } from 'react';
import { Flow } from 'flow-react';
import { observer } from "mobx-react"
import { FormattedMessage } from 'react-intl'

import ArticleCarousel from 'Component/ArcticleCarousel';
import ArticleVCard from 'Component/ArticleVCard'
import Loading from 'Component/Loading';
import { Row, Col, Form, Button, Container, Alert } from 'react-bootstrap';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { IoPencilSharp, IoMailUnreadOutline } from 'react-icons/io5';

import { Redirect } from 'react-router-dom';

const Login = observer(
  class Login extends Component {
    state = {
      loginError: false,
      user: false,
      toarticle: false,
    }

    constructor(props) {
        super(props);
        if (props.match && props.match.params.id)
            this.state.toarticle = props.match.params.id;
    }

    componentDidMount() {
        this.props.flow.addPostLoginFunction(this.flowPostLogin.bind(this));
    }

    flowPostLogin = (user) => {
        this.setState({user:user});
    }

    handleSubmit = (event) => {
      const fd = new FormData(event.currentTarget);
      event.preventDefault();
      // for (let [key, value] of formData.entries()) { console.log(key, value); }
      this.props.flow.login(fd.get('user'),fd.get('pass')).then(res=>{
        // console.log('Loggedin!',res.user);
        // return <Redirect to={{pathname: "/"}} />;
      }).catch(err=>{
        this.setState({loginError: true});
      });
    }

    render() {
      if (this.state.user) {
        if (this.state.toarticle)
          return (<Redirect to={{pathname: '/article/'+this.state.toarticle}} />);
        return (<Redirect to={{pathname: '/'}} />);
      }

      return (
        <Container className="Login">
          <Form onSubmit={this.handleSubmit}>
            <Form.Group controlId="formBasicUser">
              <Form.Label><FormattedMessage id="login.user" /></Form.Label>
              <Form.Control name="user" type="text" />
            </Form.Group>

            <Form.Group controlId="formBasicPassword">
              <Form.Label><FormattedMessage id="login.password" /></Form.Label>
              <Form.Control name="pass" type="password" />
            </Form.Group>
            { this.state.loginError && (
              <Alert variant="danger"><FormattedMessage id="login.loginError" /></Alert>
            ) }
            <Row>
                <Col xs={6} lg={3}>
                    <Button className="w-75" variant="primary" type="submit"> <FormattedMessage id="login.login" /> </Button>
                </Col>
                <Col className='d-none d-lg-block'></Col>
                <Col xs={6} lg={3} className='text-right'>
                    <Link to="/register">
                        <Button variant="secondary" className="w-100"> <IoPencilSharp /> <FormattedMessage id="login.register" /> </Button>
                    </Link>
                    &nbsp; &nbsp;
                    <Link to="/forgotpass">
                        <Button variant="secondary" className="w-100"> <IoMailUnreadOutline /> <FormattedMessage id="login.forgotpass" /> </Button>
                    </Link>
                </Col>
            </Row>
            <Row>&nbsp;</Row>
          </Form>
        </Container>
      );
    }
  }
)

export default Flow.injectFlow(Login);