import Loading from '../Loading';
import { Row, Col, Card } from 'react-bootstrap';
import { Component } from 'react';
import { Link } from 'react-router-dom';

import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import 'leaflet/dist/leaflet.css';
import './ArticleMap.scss';
import { FormattedMessage } from 'react-intl';

import pin from '../../assets/pin.svg';
import { Icon } from 'leaflet';

class ArticleMap extends Component {

    /*
    constructor(props) {
        this.map = useMapEvent('click', this.mapClick );
    }

    mapClick(event) {
        console.log('MAPCLICK');
    }
    */

    render() {
      let m = this.props.article.getMetadata();
      let position = m.get('geocoords');

      if (!position)
        return null;
      position = position.split(',');
      if (position.length != 2)
        return null;

      let popup = m.get('location');

      const mappin = new Icon({
       iconUrl: pin,
       iconSize: [32,32]
      })

      return (
        <>
        <small>
            <a target="_blank" href={`http://www.openstreetmap.org/?mlat=${position[0]}&mlon=${position[1]}&zoom=12`}><FormattedMessage id="map.header" /></a>
        </small>
        <MapContainer center={position} zoom={13} scrollWheelZoom={true} className="ArticleMap">
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.de/{z}/{x}/{y}.png"
          />
          <Marker position={position} icon={mappin}>
              <Popup>{ popup }</Popup>
          </Marker>
        </MapContainer>
        </>
      );
      /*
          <Marker position={position}>
            <Popup>
              A pretty CSS3 popup. <br /> Easily customizable.
            </Popup>
          </Marker>
          */
    }

}

export default ArticleMap;