import './Overlay.scss';
import { IoImages, IoCloseCircleOutline, IoArrowBackCircle, IoArrowForwardCircle, IoImagesOutline, IoImageOutline, IoResizeOutline, IoFolderOutline } from "react-icons/io5";

import { Component } from 'react';
import { Flow } from 'flow-react';
import { observer } from "mobx-react"
import { FormattedMessage } from 'react-intl';

import ClipThumbnail from 'Helper/ClipThumbnail';
import { Row, Col, ListGroup, Image, Container, Card, Badge, Button, Alert } from 'react-bootstrap';
import VideoPlayer from './VideoPlayer';
import ClipImage from './ClipImage';

import clipGetType from '../Helper/clipGetType';

const Overlay = observer(
    class Overlay extends Component {
      state={
          hide: true,
          mediafile: null,
        }
  
      constructor(props) {
        super(props);
  
        if (props.hide)
            this.state.hide = props.hide;
        if (props.clip)
            this.loadMediafile();
      }

      componentWillReceiveProps(props) {
        if (props.clip) {
            if (typeof props.hide !== 'undefined')
                this.setState({hide: props.hide},this.loadMediafile);
            else
                this.setState({},this.loadMediafile);
        } else {
            if (typeof props.hide !== 'undefined')
                this.setState({hide: props.hide});
        }
      }

      loadMediafile = ()=>{
        this.props.clip.getMediafiles().getAll().then(mfs=>{
            let retmf = null;
            mfs.some(mf=>{ retmf = mf; return mf.getSourcefile(); });
            this.setState({mediafile: retmf});
        });
      }

      downloadAllClick = ()=>{
        if (clipGetType(this.props.clip) == 'video') {
            if (this.props.downloadVideosClick)
                this.props.downloadVideosClick()
        } else {
            if (this.props.downloadPicturesClip)
                this.props.downloadPicturesClip()
        }
      }

      downloadClipClick = ()=>{
        if (this.props.downloadClipClick)
            this.props.downloadClipClick();
      }

      nextClick = ()=>{
        if (this.props.nextClick)
            this.props.nextClick();
      }

      prevClick = ()=>{
        if (this.props.prevClick)
            this.props.prevClick();
      }

      // https://stackoverflow.com/questions/15900485/correct-way-to-convert-size-in-bytes-to-kb-mb-gb-in-javascript
      formatBytes = (bytes, decimals = 2) => {
          if (bytes === 0) return '0 Bytes';
      
          const k = 1024;
          const dm = decimals < 0 ? 0 : decimals;
          const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
      
          const i = Math.floor(Math.log(bytes) / Math.log(k));
      
          return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
      }
  
      render() {
        let c = this.props.clip;
        if (!c)
          return null;

        let cmedia = c.getMedia();
        
        let ctype = clipGetType(c);
        if (ctype == 'unknown') {
          console.error('Unknown Media',cmedia);
          return null;
        }

        let meta = {
            bytes: '',
            size: '',
        }

        if (this.state.mediafile) {
            let mf = this.state.mediafile;
            meta.bytes = this.formatBytes(mf.getFilesize());
            meta.size = mf.getWidth()+'x'+mf.getHeight();
        }

        return (
          <div className={`Overlay ${this.state.hide ? 'hide' : 'show'}`}>

            <div className='inner'>
                <div className='omedia'>
                    { ctype == 'picture' ?
                        <ClipImage noplaceholder={true} clip={this.props.clip} className="mx-auto" rounded fluid />
                    :
                        <VideoPlayer clip={this.props.clip} project={this.props.project} />
                    }
                    <IoArrowBackCircle onClick={this.prevClick} />
                    <IoArrowForwardCircle onClick={this.nextClick} />
                </div>
                <div className='ometa'>
                    <div className='left text-left text-nowrap pl-3'>
                        <div className='font-weight-bold'>{this.props.clip.getTitle()}</div>
                        <div><IoFolderOutline /> {meta.bytes} &nbsp; &nbsp; &nbsp; <IoResizeOutline /> {meta.size}</div>
                    </div>
                    <div className='right text-right text-nowrap pr-3'>
                        <div className='pointer' onClick={this.downloadClipClick}><IoImageOutline /> <FormattedMessage id="download.this" /></div>
                        <div className='pointer' onClick={this.downloadAllClick}>
                            { this.props.downloadAll &&
                            <>
                                { ctype == 'video' && <><IoImages /> <FormattedMessage id="download.videos" /></> }
                                { ctype == 'picture' && <><IoImagesOutline /> <FormattedMessage id="download.pictures" /></> }
                            </>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className="close" onClick={()=>{ this.setState({hide:true}); }}>
                <IoCloseCircleOutline />
            </div>

          </div>
        );
      }

    }
  );
  
  export default Flow.injectFlow(Overlay);