import { Component } from 'react';
import { Flow } from 'flow-react';
import { observer } from "mobx-react"
import { Row, Col, Carousel, Spinner, Container } from 'react-bootstrap';
import { FormattedMessage, FormattedTime, FormattedDate } from 'react-intl'
import ArticleThumbnail from 'Helper/ArticleThumbnail';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import Loading from './Loading';

import './ArticleCarousel.scss'
import { propTypes } from 'react-gallery-carousel';
import ClipImage from './ClipImage';

import toDate from 'Helper/toDate';
import Bundesland2Short from 'Helper/Bundesland2Short';

const ArticleCarousel = observer(
  class ArticleCarousel extends Component {
    state={
        articles: [],
        thumbclips: {}
    }

    constructor(props) {
      super(props);

      if (props.articles)
        this.state.articles = props.articles;
      if (props.thumbclips)
        this.state.thumbclips = props.thumbclips;
    }

    componentWillReceiveProps(props) {
        if (props.articles || props.thumbclips) {
            let s = {};
            if (props.articles)
                s.articles = props.articles;
            if (props.thumbclips)
                s.thumbclips = props.thumbclips;

            this.setState(s);
        }
    }

    render() {
        if (!this.state.articles || !this.state.thumbclips) {
            return <Loading />;
        }

        let carouselItem = (article,clip) => {
            let a = article;
            let m = article.getMetadata();
            let bula = Bundesland2Short(m.get('state'));

            let country = m.get('country') ? m.get('country').substr(0,3).toUpperCase() : '';
            return (
                <Carousel.Item key={article.getId()}>
                    <Link to={`/article/${article.getId()}`}>
                        <ClipImage className="d-block w-100" thumbmode={true} clip={clip} preventObjectFit={true} force169={false} width={910} height={512} fluid/> 
                        <Carousel.Caption>
                            <Container className='caroucaptioncontainer'>
                                <Row>
                                    <Col xs={12}>
                                        <span className='font-weight-bold'>{a.getId()}</span>
                                        <span className='mx-1'>|</span>
                                        <span className="date text-nowrap overflow-hidden">{ !!m.get('eventstart') && <FormattedDate value={toDate(m.get('eventstart'))} year="numeric" month="2-digit" day="2-digit" /> }</span>
                                        <span className='mx-1'>|</span>
                                        <span className="geo text-right text-nowrap overflow-hidden">
                                            {country} 
                                            { bula && 
                                                <>
                                                <span className='mx-1'>|</span>
                                                <span>{bula}</span>
                                                </>
                                            }
                                            { country.length > 0 && m.get('location') && ' | '}
                                            {m.get('location')}
                                        </span>
                                    </Col>
                                </Row>
                                <Row>&nbsp;</Row>
                                <Row>
                                    <Col xs={12}>
                                        <h4>{m.get('title')}</h4>
                                    </Col>
                                </Row>
                                <Row>&nbsp;</Row>
                                <Row>
                                    <Col xs={12}>
                                        {m.get('kicker')}
                                    </Col>
                                </Row>
                            </Container>

                        </Carousel.Caption>
                    </Link>
                </Carousel.Item>
            );
        };

        const articlesbyid = {};
        this.state.articles.forEach(a=>{ articlesbyid[a.getId()] = a; });

        const citems = [];

        let icount = 0;
        
        this.state.articles.every(article=>{
            if (icount++ > 4) // max 5
                return false;
            citems.push(carouselItem(article,this.state.thumbclips[article.getId()]));
            return true;
        });

        return (
            <Row className="ArticleCarousel">
                <Col xs={1} className="d-none d-md-block"></Col>
                <Col xs={12} md="10">
                    <Carousel>
                        {citems}
                    </Carousel>
                </Col>
                <Col xs={1} className="d-none d-md-block"></Col>
            </Row>
        )
    }
  }
)

export default Flow.injectFlow(ArticleCarousel);
