import { Component } from 'react';
import { Flow } from 'flow-react';
import { observer } from "mobx-react"
import { FormattedMessage } from 'react-intl'

import ArticleCarousel from 'Component/ArcticleCarousel';
import ArticleVCard from 'Component/ArticleVCard'
import Loading from 'Component/Loading';
import { Row, Col, Form, Button, Container, Alert } from 'react-bootstrap';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

import { Redirect } from 'react-router-dom';

const Recoverpass = observer(
  class Recoverpass extends Component {
    state = {
      email: null,
      secret: null,
      done: false,
      error: false,
      pwlengtherror: false,
    }

    constructor(props) {
      super(props);
      if (props.match) {
          if (props.match.params.email)
            this.state.email = props.match.params.email;
          if (props.match.params.secret)
            this.state.secret = props.match.params.secret;
      }
    }

    password_ok(password) {
        if (password.length > 6) {
            let numbers = 0; try { numbers = password.match(/[\d]/g).length } catch(e){}
            let sozeich = 0; try { sozeich = password.match(/[^a-z0-9]/ig).length } catch(e){}
            if (numbers > 0 && sozeich > 0)
                return true;
        }
        return false;
    }

    handleSubmit = (event) => {
      const fd = new FormData(event.currentTarget);
      event.preventDefault();
      let password = fd.get('password');
      if (this.password_ok(password)) {
        this.setState({pwlengtherror: false});
        window.flow.vifograUserSetuserpass(this.state.email,this.state.secret,password)
            .then(()=>{ this.setState({done: true}); })
            .catch(()=>{ this.setState({error: true}); });
      } else {
        this.setState({pwlengtherror: true});
      }
    }

    render() {
        if (this.state.done) {
            return (
                <>
                    <Alert variant="success"><FormattedMessage id="recoverpass.done" /></Alert>
                    <Row>&nbsp;</Row>
                </>
            );
        }

        return (
            <Container className="Recoverpass">
            <Form onSubmit={this.handleSubmit}>
                <Form.Group controlId="formBasicPassword">
                    <Form.Label><FormattedMessage id="recoverpass.password" /></Form.Label>
                    <Form.Control name="password" type="password" />
                </Form.Group>
                <Form.Group controlId="formBasicPasswordrep">
                    <Form.Label><FormattedMessage id="recoverpass.passwordrep" /></Form.Label>
                    <Form.Control name="passwordrep" type="password" />
                </Form.Group>

                <Button variant="primary" type="submit"> <FormattedMessage id="recoverpass.submit" /> </Button>
                <Row>&nbsp;</Row>
                { this.state.pwlengtherror
                    ? <Alert variant="danger"><FormattedMessage id="recoverpass.pwlengtherror" /></Alert>
                    : <Alert variant="warning"><FormattedMessage id="recoverpass.pwlengtherror" /></Alert>
                }
                { this.state.error &&
                    <>
                        <Row>&nbsp;</Row>
                        <Alert variant="danger"><FormattedMessage id="recoverpass.error" /></Alert>
                    </>
                }
                <Row>&nbsp;</Row>
            </Form>
            </Container>
        );
    }
  }
)

export default Flow.injectFlow(Recoverpass);
