import Loading from './Loading';
import { Badge, Card } from 'react-bootstrap';
import { Component } from 'react';
import { Link } from 'react-router-dom';
import ArticleThumbnail from 'Helper/ArticleThumbnail';
import ClipImage from './ClipImage';

import { Row, Col, Container } from 'react-bootstrap';


import './ArticleVCard.scss';
import { FormattedDate } from 'react-intl';
import toDate from 'Helper/toDate';
import Bundesland2Short from 'Helper/Bundesland2Short';

  class ArticleVCard extends Component {
    state={
        data: false,
        thumbclip: null,
    }

    constructor(props) {
      super(props);
      this.state.data = props.data;
      this.state.thumbclip = props.thumbclip ? props.thumbclip : null;
    }

    componentWillReceiveProps(props) {
      if (props.thumbclip)
        this.setState({thumbclip: props.thumbclip});
    }

    render() {
      if (!this.state.data)
        return <Loading />;

      const a = this.state.data;
      const m = a.getMetadata();
      // const thumb = ArticleThumbnail(a);
      //    <Card.Img variant="top" src={thumb} />

      let tags = m.get('keywords');
      tags = tags ? tags.split(',') : [];
      /*
      const tageles = tags.map((tag,i)=>{
        return (
          <Badge className="mr-1" key={`tag-${i}`} variant={ i>0 ? 'secondary' : 'primary'}>{tag}</Badge>
        );
      })
      */

      let strippedText = m.get('shortdesc');
      if (!strippedText)
        strippedText = '';
      else
        strippedText = strippedText.replace(/(<([^>]+)>)/gi, "");

      let country = m.get('country') ? m.get('country').substr(0,3).toUpperCase() : '';
      let bula = Bundesland2Short(m.get('state'));
      let hasdate = !!m.get('eventstart');

      return (
        <Card className="ArticleVCard">
          <Link to={`/article/${a.getId()}`} className="h-100">
            <ClipImage className="mx-auto mb-2" force169={true} thumbmode={true} clip={this.state.thumbclip} width={500} height={281} thumbnail={false} fluid/> 
            <Card.Body>
              <Card.Text className="dateline text-muted">
                  <span className='font-weight-bold'>{a.getId()}</span>
                  <span className='mx-1'>|</span>
                  <span className="date text-nowrap overflow-hidden">{ hasdate && <FormattedDate value={toDate(m.get('eventstart'))} year="numeric" month="2-digit" day="2-digit" /> }</span>
                  { hasdate && <span className='mx-1'>|</span> }
                  <span className="geo text-nowrap overflow-hidden">
                    {country} 
                    { bula && 
                      <>
                        <span className='mx-1'>|</span>
                        <span>{bula}</span>
                      </>
                    }
                    { country.length > 0 && m.get('location') && ' | '}
                    {m.get('location')}
                  </span>
              </Card.Text>
              <Card.Title>{m.get('title')}</Card.Title>
              <Card.Text className="small shortd">{strippedText}</Card.Text>
            </Card.Body>
          </Link>
        </Card>
      );
    }

  }

export default ArticleVCard;