/**
 * string to Date
 */

const toDate = function(value) {
    if (value.split)
        value = value.split(' ')[0];
    let m = value.trim().match(/(?<y>[\d]{4})-(?<m>[\d]{2})-(?<d>[\d]{2}) (?<h>[\d]{2}):(?<n>[\d]{2}):(?<s>[\d]{2})/);
    if (m) {
        m = m.groups;
        m.m = parseInt(m.m)-1;
        return Date.UTC(m.y,m.m,m.d,m.h,m.n,m.s,0);
    }
    return Date.parse(value)
}

export default toDate;