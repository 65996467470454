import './Pagi.scss';
import Loading from './Loading';
import { Row, Col, Card, Container, Pagination } from 'react-bootstrap';
import { Component } from 'react';
import { Link } from 'react-router-dom';

import Carousel from 'react-gallery-carousel';
import 'react-gallery-carousel/dist/index.css';
import ClipThumbnail from 'Helper/ClipThumbnail';

class Pagi extends Component {
    state = {
        offset: 0,
        limit: 0,
        count: 0,
        tooffset: null,
    };

    constructor(params) {
        super(params);
        ['offset','limit','count','tooffset'].forEach(n=>{
            if (params[n])
                this.state[n] = params[n];
        });
    }

    componentWillReceiveProps(params) {
        this.setState(params);
    }

    toOffset(offset) {
        if (this.state.tooffset)
            this.state.tooffset(offset);
    }

    render() {
        let pages = Math.ceil(this.state.count / this.state.limit);

        let currentpage = Math.floor(this.state.offset / this.state.limit);

        let prevoff = this.state.offset-this.state.limit;
        if (prevoff < 0)
            prevoff = 0;
        
        let nextoff = (currentpage+1)*this.state.limit;

        let showprev = this.state.offset > 0;
        let shownext = currentpage+1 < pages;

        let pitems = [];

        // console.log(`page ${currentpage} of ${pages} / count:${this.state.count} limit:${this.state.limit} offset:${this.state.offset}`);

        let pistart = currentpage - 2;
        if (pistart > (pages-5))
            pistart = pages-5;
            
        if (pistart < 0)
            pistart = 0

        for (let pnum = pistart; pnum <= pages; pnum++) {
            if (pnum > pistart+4)
                break;
            pitems.push(<Pagination.Item active={pnum==currentpage} onClick={()=>{ this.toOffset(pnum*this.state.limit); }}>{pnum+1}</Pagination.Item>);
        }
        // <Pagination.Ellipsis />

        if (pitems.length < 2)
            return <Container className="Pagi"></Container>

        return (
            <Container className="Pagi">
                <Row>
                    <Col xs={12} className='text-center' style={{display: 'flex', justifyContent: 'center'}}>
                        <Pagination size='sm'>
                            { showprev &&
                                <>
                                <Pagination.First onClick={()=>{this.toOffset(0)}} />
                                <Pagination.Prev onClick={()=>{this.toOffset(prevoff)}}/>
                                </>
                            }
                            {pitems}
                            { shownext &&
                                <>
                                <Pagination.Next onClick={()=>{this.toOffset(nextoff)}}/>
                                <Pagination.Last onClick={()=>{this.toOffset((pages-1)*this.state.limit)}}/>
                                </>
                            }
                        </Pagination>
                    </Col>
                </Row>

            </Container>
        );
    }

}

export default Pagi;