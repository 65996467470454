import Loading from './Loading';
import { Row, Col, Card } from 'react-bootstrap';
import { Component } from 'react';
import { Link } from 'react-router-dom';

import Carousel from 'react-gallery-carousel';
import 'react-gallery-carousel/dist/index.css';
import ClipThumbnail from 'Helper/ClipThumbnail';

import './Gallery.scss';

class Gallery extends Component {
  // SEE: https://github.com/yifaneye/react-gallery-carousel
  /*
  state={
      data: false
  }

  constructor(props) {
    super(props);
    this.state.data = props.data;
  }
  */

  render() {
    const carouselImages = this.props.clips.map((clip,i)=>{
      return { src: ClipThumbnail(clip,240,180) };
    });

    return (
      <div className="Gallery">
        <Carousel images={carouselImages} />
      </div>
    );
  }

}

export default Gallery;