/**
 * Bundesland to 2-letter
 */

const stmap = {"baden-württemberg":"BW","bayern":"BY","berlin":"BE","brandenburg":"BB","bremen":"HB","hamburg":"HH","hessen":"HE","mecklenburg-vorpommern":"MV","niedersachsen":"NI","nordrhein-westfalen":"NW","rheinland-pfalz":"RP","saarland":"SL","sachsen":"SN","sachsen-anhalt":"ST","schleswig-holstein":"SH","thüringen":"TH"};

const Bundesland2Short = function(state) {
    if (!state)
        return null;
    state = state.toLowerCase();
    return !!stmap[state] ? stmap[state] : null;
}

export default Bundesland2Short;