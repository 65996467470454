import { Component } from 'react';
import './CookieInfo.scss';
import { Row, Col, Container } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl'

import {IoLogoFacebook, IoLogoInstagram, IoLogoTwitter} from "react-icons/io5";

class CookieInfo extends Component {
    state = {
        hidden: false,
    };
    cookiename = 'cookieaccepted';
    cookiedays = 14;

    constructor(props) {
        super(props);

        this.state.hidden = document.cookie.includes(this.cookiename+'=');
    }

    confirmClick = () => {
        let expires = (new Date(Date.now()+ 86400*1000*this.cookiedays)).toUTCString();
        document.cookie = `${this.cookiename}=true; expires=" + ${expires} + ";path=/;`;

        this.setState({hidden: true});
    }

    rejectClick = () => {
        if (this.props.cookies) {
            this.props.cookies.forEach(n=>{ 
                document.cookie = n+'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
            });
        }
        document.cookie = this.cookiename+'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';

        this.setState({hidden: true});
    }

    render() {
        if (this.state.hidden)
            return null;

        return (
            <div className="CookieInfo bg-light border border-primary rounded">
                <Row className="pt-2 my-2 mx-2">
                    <Col>
                        <Row className="mb-2">
                            <FormattedMessage id="cookieinfo.text" />
                        </Row>
                        <Row>
                            <Col xs={12} className='text-right'>
                                <button onClick={this.confirmClick} type="button" class="btn btn-primary"><FormattedMessage id="cookieinfo.confirm" /></button>
                            </Col>
                            { /*
                            <Col xs={6} className='text-right'>
                                <button onClick={this.rejectClick} type="button" class="btn btn-warning"><FormattedMessage id="cookieinfo.reject" /></button>
                            </Col>
                            */ }
                        </Row>
                    </Col>
                </Row>
            </div>
        );
    }
  
}
  
export default CookieInfo;