import { Component } from 'react';
import { Flow } from 'flow-react';
import { observer } from "mobx-react"
import { FormattedMessage, injectIntl } from 'react-intl'

import ArticleCarousel from 'Component/ArcticleCarousel';
import ArticleVCard from 'Component/ArticleVCard'
import Loading from 'Component/Loading';
import { Row, Col, Form, Button, Container, Alert } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { IoCheckmarkSharp } from 'react-icons/io5';

const Login = observer(
  class Login extends Component {
    state = {
      fdata: {},
      ferror: {},
      done: false,
      failed: false,
    }

    basefields = ['email','firstname','lastname','company','street','zip','city','country','phone'];
    addfields = ['mobile'];

    registerClick = () => {
        if (!this.hasErrors()) {
            this.props.flow.vifograUserRegister(this.state.fdata)
                .then(()=>{ this.setState({done: true }); })
                .catch(()=>{ this.setState({failed: true }) });
        }
    }

    hasErrors = () => {
        let haserr = false,
            errs = {};
        
        for (let name of this.basefields) {
            if (!this.state.fdata[name] || this.state.fdata[name].length < 3) {
                haserr = true;
                errs[name] = true;
            } else {
                errs[name] = false;
            }
        }

        if (!this.state.fdata.role || this.state.fdata.role=='null') {
            haserr = true;
            errs.role = true;
        }

        /*
        if (!this.state.fdata.acceptprivacy) {
            haserr = true;
            errs.acceptprivacy = true;
        }
        */

        this.setState({ferror: errs});

        return haserr;
    }

    formChange = e => {
      let nfdata = this.state.fdata;

      nfdata[e.target.getAttribute('name')] = e.target.getAttribute('type') === 'text' || e.target.tagName.toLowerCase() == 'select' ? 
                                                e.target.value.trim() : 
                                                !!e.target.checked;
      this.setState({fdata: nfdata});
    }

    render() {
        let fields = [];
        for (let name of [...this.basefields, ...this.addfields]) {
            fields.push(
                <Form.Group controlId={name} key={name}>
                    <Form.Label><FormattedMessage id={`register.${name}`} /></Form.Label>
                    <Form.Control name={name} type="text" onChange={this.formChange}/>
                    { this.state.ferror[name] &&
                        <Form.Text className="text-danger"><FormattedMessage id={`register.err.${name}`} /></Form.Text>
                    }
                </Form.Group>
            );
        }

        fields.push(
            <Form.Group controlId='role' key='role'>
                <Form.Label><FormattedMessage id='register.role' /></Form.Label>
                { this.state.ferror['role'] &&
                    <Form.Text className="text-danger"><FormattedMessage id='register.err.role' /></Form.Text>
                }
                { /*
                <Form.Select>
                    <option><FormattedMessage id='register.choose' /></option>
                    <option value="Kunde"><FormattedMessage id='register.kunde' /></option>
                    <option value="Reporter"><FormattedMessage id='register.reporter' /></option>
                    <option value="Rettungskraft"><FormattedMessage id='register.rettungskraft' /></option>
                </Form.Select>
                */ }
                <select class="form-control" name="role" onChange={this.formChange}>
                    <option value="null">Bitte wählen</option>
                    <option value="Kunde">Kunde</option>
                    <option value="Reporter">Reporter</option>
                    <option value="Rettungskraft">Rettungskraft</option>
                </select>
            </Form.Group>
        )

        if (this.state.done) {
            return ( <>
                <Alert variant="success">
                    <Container>
                        <Row><FormattedMessage id="register.thanks" /></Row>
                        <Row><FormattedMessage id="register.thanks2" /></Row>
                    </Container>
                </Alert>
                <Row>&nbsp;</Row>
            </>);
        }

        // let txt_acceptprivacy = this.props.intl.formatMessage({id:'register.acceptprivacy'});
        /*
              <Form.Group controlId="formBasicCheckbox">
                <Form.Check name="acceptprivacy" type="checkbox" label={txt_acceptprivacy} onChange={this.formChange}/>
                <Form.Text className="text-muted"><Link to="/privacy" className="text-primary" target="_blank"><FormattedMessage id="register.readprivacy" /></Link></Form.Text>
                { this.state.ferror.acceptprivacy &&
                    <Form.Text className="text-danger"><FormattedMessage id="register.err.acceptprivacy" /></Form.Text>
                }
              </Form.Group>
        */


        return (
          <Container className="Register">
            <Row>
                <Col xs={12}>
                    <h3><FormattedMessage id="register.header" /></h3>
                    <p><FormattedMessage id="register.text" /></p>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <Form>
                    {fields}
                    <Button variant="primary" onClick={this.registerClick}>
                        <FormattedMessage id="register.register" />
                    </Button>
                    </Form>
                    <br/>
                    { this.state.failed && 
                    <Alert variant="danger"><FormattedMessage id="register.failed" /></Alert>
                    }
                    <br/>
                </Col>
            </Row>
          </Container>
        );
    }
  }
)

export default injectIntl(Flow.injectFlow(Login));