import Loading from './Loading';
import { Row, Col, Card, Button } from 'react-bootstrap';
import { Component } from 'react';
import { Link } from 'react-router-dom';
import { FormattedDate, FormattedTime, FormattedMessage, injectIntl } from 'react-intl';
import { IoSwapVerticalSharp, IoEllipsisHorizontal } from "react-icons/io5";

import { Flow } from 'flow-react';
import { observer } from "mobx-react"

import './ClipHCard.scss';
import ClipImage from './ClipImage';
import toDate from 'Helper/toDate';

const ClipHCard = observer(
  class ClipHCard extends Component {
    state={
        data: false,
        comments: [],
        mediafiles: [],
        more: false,
        directDownloadClick: false,
    }

    constructor(props) {
        super(props);
        this.state.data = props.data;
        this.state.comments = props.comments;
        this.state.comments.sort((a, b) => { return a.getTcin() - b.getTcin(); });
        this.state.directDownloadClick = props.directDownloadClick;

        this.getMediafiles();
    }

    getMediafiles() {
        this.state.data.getMediafiles().getAll().then((mfs)=>{
            this.setState({mediafiles: mfs});
        });
    }

    onVideoClick = ()=>{
      if (this.props.onVideoClick) 
        this.props.onVideoClick(this.state.data);
    }

    onMoreClick = ()=>{
      this.setState({more: !this.state.more});
    }

    render() {
      if (!this.state.data)
        return <Loading />;

      const clip = this.state.data;
      const m = clip.getMetadata();

      let showcomts = false;
      this.state.comments.every(c=>{
        let vin = parseFloat(c.getTcin());
        if (vin != NaN && vin > 0)
          showcomts = true;
        let vout = parseFloat(c.getTcout());
        if (vout != NaN && vout > 0)
          showcomts = true;

        return !showcomts;
      })
      const comms = this.state.comments.map(c=>{
        let dsec = (sec)=>{
            sec = parseFloat(sec);
            let m = (Math.floor(sec/60)+'').padStart(2,'0');
            let s = (Math.floor(sec%60)+'').padStart(2,'0');
            return `${m}:${s}`;
        };

        let timedis = (tcin,tcout) => {
            let a = dsec(tcin);
            let b = dsec(tcout);
            if (a == b)
                return a;
            return `${a} - ${b}`;
        }

        return (
          <li>
              {showcomts && 
                <>
                  <small>{timedis(c.getTcin(),c.getTcout())}</small> 
                  <span>{c.getName()}:</span>
                </>
              } 
              <span dangerouslySetInnerHTML={{__html: c.getComment()}} />
          </li>
        );
      });

      let mfs = this.state.mediafiles;
      let mfsource = mfs.find((mf)=>{ return !!mf.getSourcefile(); })
      let mffilename = mfs.find((mf)=>{ return mf.hasTag('preview'); })
      if (!mffilename)
        mffilename = mfsource;

      let laenge = '';
      let filename = this.state.data.getTitle();
      if (mfsource) {
        let sec = parseFloat(mfsource.getSeconds());
        let m = '00';
        if (sec >= 60)
            m = (Math.floor(sec/60)+'').padStart(2,'0');
        let s = (Math.floor(sec%60)+'').padStart(2,'0');

        laenge = `${m}:${s}`;
        // filename = mffilename.getFilename();
      }
                
      let tp = m.get('txt8');
      switch (tp) {
          case 'OToene': tp = this.props.intl.formatMessage({id:'card.oton'}); break;
          // case 'Schnittbilder': tp = this.props.intl.formatMessage({id:'card.schnittbild'}); break;
          // default: tp = m.get('txt8'); // this.props.intl.formatMessage({id:'card.typedefault'}); break;
      }

      // Redaktioneller Hinweis
      let redinfo = [];
      let txtseven = m.get('txt7');
      if (txtseven) {
        m.get('txt7').split(';').forEach(tag=>{
            switch (tag) {
            case 'Nutzungsrechte': redinfo.push('card.nutzungsrechte'); break;
            case 'Privatpersonen': redinfo.push('card.privatpersonen'); break;
            case 'Qualitätsstandard': redinfo.push('card.qualitaetsstandard'); break;
            case 'Leichenteile': redinfo.push('card.leichenteile'); break;
            case 'Verletzte': redinfo.push('card.verletzte'); break;
            case 'Angehörigenverständigung': redinfo.push('card.angehoerigenverstaendigung'); break;
            }
        });
      }

      let redinfoeles = redinfo.map(tag=>{
        return (<div className='font-weight-bold text-danger'>
                 <FormattedMessage id="card.redinfo" />
                 <FormattedMessage id={tag} />
               </div>);
      });

      let txtnine = m.get('txt9');
      if (txtnine) {
        redinfoeles.push(<div className='font-weight-bold text-danger'>
                           <FormattedMessage id="card.redinfo" />
                           <>{txtnine}</>
                         </div>);
      }


      return (
        <Card className="ClipHCard mb-3">
          <Card.Body>
            <Row noGutters>
              <Col md={4} onClick={this.onVideoClick}>
                <ClipImage force169={true} thumbmode={true} clip={clip} width={415} height={233} />
              </Col>
              <Col md={8} className="pl-2">
                <Card.Title className='schot-title'><Button variant='primary' onClick={this.state.directDownloadClick}><FormattedMessage id="card.download" /></Button> &nbsp; <strong>{tp}</strong> &nbsp; <FormattedMessage id="card.cliplength" values={{'length': laenge}} /></Card.Title>
                <Card.Text className='mt-3'>
                    { m.get('int1')=='1' &&
                      <span className='text-danger font-weight-bold'><FormattedMessage id="card.filenew" /> </span>
                    }
                    <FormattedMessage id="card.filename" /> <span>{filename}</span>
                </Card.Text>
                { redinfoeles.length > 0 && 
                  <Card.Text>
                      {redinfoeles}
                  </Card.Text>
                }
                <Card.Text className={'comments'+(this.state.more?' open':'')}>
                  <ul>{comms}</ul>
                  { /*
                  <br/>
                  <p>{m.get('txt4')}</p>
                  */ }
                  <div className="more" onClick={this.onMoreClick}><IoEllipsisHorizontal className="icon" /> </div>
                </Card.Text>
              </Col>
            </Row>
          </Card.Body>
          <Card.Footer className="text-right px-2 py-1">
            <small className="text-muted">
              <FormattedDate value={toDate(m.get('lastchange'))} year="numeric" month="2-digit" day="2-digit" />
              { /*
              &nbsp;
              <FormattedTime value={toDate(m.get('lastchange'))} hour="numeric" minute="numeric" />
                */ }
            </small>
          </Card.Footer>
        </Card>
      );
    }

  }
);

export default injectIntl(Flow.injectFlow(ClipHCard));